import { MaybeArray } from '@shared/types';
import { AppUserRolePermissionEnum } from '@shared/types/graphql.gen';
import { MaybeRefOrGetter, computed, toValue } from 'vue';
import { useCurrentUserContext } from './currentUser';
import { keyBy, merge } from 'lodash-es';
import { RouteRecordRaw, useRouter } from 'vue-router';
import { createAppError } from '@shared/utils/errors';
import { until } from '@vueuse/core';

/**
 * Adds a guard for permission locked routes.
 */
export function useRoutePermissionGuard() {
  const { user: currentUser } = useCurrentUserContext();
  const router = useRouter();
  const userPermissions = computed(() => {
    return currentUser.value ? keyBy(currentUser.value.role.permissions) : {};
  });

  router.beforeResolve(async to => {
    await until(() => !!currentUser.value?.id).toBe(true);

    const routePermissions = to.meta.permissions as undefined | AppUserRolePermissionEnum[];
    // No permissions or empty permissions specified for route
    if (!routePermissions?.length) {
      return true;
    }

    // user has one of the permissions allowed by the route
    if (routePermissions.some(p => userPermissions.value[p]) || currentUser.value?.role.isAdmin) {
      return true;
    }

    // User doesn't have permissions
    throw createAppError({
      name: 'UnauthorizedError',
      message:
        'You do not have permissions to access this page, please contact one of your workspace admins to give you access.',
      code: 403,
    });
  });
}

export function withRoutePermissions(route: RouteRecordRaw, permissions: AppUserRolePermissionEnum[]): RouteRecordRaw {
  return merge(route, {
    meta: {
      permissions,
    },
  });
}

export function useHasPermissions() {
  const { user: currentUser } = useCurrentUserContext();
  const userPermissions = computed(() => {
    return currentUser.value ? keyBy(currentUser.value.role.permissions) : {};
  });

  return function hasPermission(permissions: MaybeArray<AppUserRolePermissionEnum>) {
    const allowList = toValue(permissions);
    const list = Array.isArray(allowList) ? allowList : [allowList];

    return list.some(p => userPermissions.value[p]) || currentUser.value?.role.isAdmin;
  };
}

export function useIsAllowedTo(permissions: MaybeRefOrGetter<MaybeArray<AppUserRolePermissionEnum>>) {
  const hasPermission = useHasPermissions();

  const isAllowed = computed(() => {
    return hasPermission(toValue(permissions));
  });

  return isAllowed;
}

useIsAllowedTo.not = function useIsNotAllowedTo(permissions: MaybeRefOrGetter<MaybeArray<AppUserRolePermissionEnum>>) {
  const hasPermission = useHasPermissions();
  const isNotAllowed = computed(() => {
    return !hasPermission(toValue(permissions));
  });

  return isNotAllowed;
};
