import { InjectionKey } from 'vue';
import { VueFlowStore } from '@vue-flow/core';
import { NotificationSoundApi } from '@web/features/notifications';
import { ChannelConfigForm } from '@web/features/channelConfig';
import { AffiliateContext } from '@web/features/tracking';
import { SideMenuContext } from '@web/features/sideMenu';
import { AppIntegrationsContext, CurrentAppUserIntegrationsContext } from '@web/features/integrations';
import { KbdShortcutContext } from '@web/features/shortcuts';
import { FloatingContainerParentContext } from '@web/components/FloatingContainer.vue';
import { SearchPanelContext } from '@web/features/search';
import { CommandPaletteContext, CommandPaletteHighlightedItemContext } from '@web/features/commandPalette';
import { ActiveStoreContext, AppCommerceStoresContext } from '@web/features/commerce/stores';
import { GlobalTooltipContext } from '@web/features/tooltips';
import { AppTagsContext } from '@web/features/tags';
import { AppDataAttrsContext } from '@web/features/dataAttrs';
import { ContactImportContext } from '@web/features/channelUsers/importing';
import { BotManagerContext } from '@web/features/bots/manager';
import { AppOnboardingContext } from '@web/features/onboarding';

export const NotificationSoundApiCtxInjectionKey: InjectionKey<NotificationSoundApi> = Symbol(
  'Context for playing a notification sound',
);

export const ActivationFormCtxInjectionKey: InjectionKey<ChannelConfigForm> = Symbol('Channel activation form context');

export const AffiliateContextKey: InjectionKey<AffiliateContext> = Symbol('Affiliate tracking Context');

export const SlideViewContextKey: InjectionKey<{ close: () => void; updateView(view: string): void }> =
  Symbol('Slide view context');

export const SideMenuContextKey: InjectionKey<SideMenuContext> = Symbol('Side menu context');

export const AppIntegrationsContextKey: InjectionKey<AppIntegrationsContext> = Symbol('App Integrations context');
export const CurrentAppUserIntegrationsContextKey: InjectionKey<CurrentAppUserIntegrationsContext> = Symbol(
  'Current App User Integrations context',
);
export const FloatingContainerParentContextKey: InjectionKey<FloatingContainerParentContext> = Symbol(
  'Floating Container Parent Context',
);

export const KeyboardShortcutsKey: InjectionKey<KbdShortcutContext> = Symbol('Keyboard shortcuts key');

export const SearchPanelContextKey: InjectionKey<SearchPanelContext> = Symbol('Search panel context');

export const CommandPaletteContextKey: InjectionKey<CommandPaletteContext> = Symbol('Command palette context');

export const AppCommerceStoresContextKey: InjectionKey<AppCommerceStoresContext> =
  Symbol('App commerce stores context');

export const ActiveCommerceStoreContextKey: InjectionKey<ActiveStoreContext> = Symbol('Conversation commerce context');

export const GlobalKeyboardTooltipKey: InjectionKey<GlobalTooltipContext> = Symbol('Global tooltip context');

export const AppOnboardingContextKey: InjectionKey<AppOnboardingContext> = Symbol('App onboarding context');

export const AppTagsContextKey: InjectionKey<AppTagsContext> = Symbol('App tags context');

export const AppDataAttrsContextKey: InjectionKey<AppDataAttrsContext> = Symbol('App data attributes context');

export const ContactImportContextKey: InjectionKey<ContactImportContext> = Symbol('Contact importing context key');

export const VueFlowContextKey: InjectionKey<VueFlowStore> = Symbol('Vue flow context');

export const BotMutationManagerContextKey: InjectionKey<BotManagerContext> = Symbol(
  'Workflow Bot mutation manager context',
);

export const CommandPaletteHighlightContextKey: InjectionKey<CommandPaletteHighlightedItemContext> = Symbol(
  'Command palette highlight context',
);
