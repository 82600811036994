<template>
  <div v-if="hasTriggerSlot" ref="anchorRef" v-bind="$attrs" v-on="fixedTriggerHandlers">
    <slot name="trigger" :is-open="shouldShow" />
  </div>

  <teleport to="body">
    <div
      v-if="shouldShow && adaptive && !hasParent"
      class="desktop-sm:hidden fixed inset-0 z-50 w-full h-full bg-gray-500 opacity-75"
      @click="close"
    ></div>

    <div
      :id="`FloatingContainer-${id}`"
      ref="menuRef"
      class="menu-container"
      :class="{ 'is-adaptive': adaptive, 'pointer-events-none': nonInteractive }"
      @mouseleave="onLeave"
      @mouseenter="onEnter"
    >
      <transition :name="menuTransitionName">
        <div v-if="shouldShow" role="menu" aria-orientation="vertical" :aria-labelledby="id" class="menu">
          <slot name="menu" :data="data" :close="close" :is-open="shouldShow" />
        </div>
      </transition>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeUnmount, onMounted, watch, provide, inject, useSlots } from 'vue';
import { useRoute } from 'vue-router';
import { Middleware, Placement } from '@floating-ui/core';
import type { ClientRectObject } from '@floating-ui/core';
import { computePosition, getOverflowAncestors, autoPlacement, detectOverflow, flip, shift } from '@floating-ui/dom';
import { Position } from '@web/types';
import { useIsMobileScreen } from '@shared/features/screen';
import { FloatingContainerParentContextKey } from '@web/injectionKeys';
import {
  FloatingContainerContext,
  TriggerEvent,
  useFloatingContainerContext,
  getAutoIncrementId,
} from '@web/features/floatingMenus';
import { useMixpanel, TrackEvent } from '@web/features/tracking';
import { useResizeObserver } from '@vueuse/core';

export interface FloatingContainerParentContext {
  id: string;
  register(instance: FloatingContainerContext): void;
  unregister(instance: FloatingContainerContext): void;
}

export type { FloatingContainerContext };

const props = withDefaults(
  defineProps<{
    id?: string;
    position?: Position | null;
    label?: string;
    placement?: Placement;
    autoPlace?: boolean;
    events?: TriggerEvent[];
    delay?: number;
    disabled?: boolean;
    adaptive?: boolean;
    menuTransitionName?: string;
    matchTriggerWidth?: boolean;
    nonInteractive?: boolean;
    trackingEvent?: TrackEvent;
    toggles?: boolean;
  }>(),
  {
    id: getAutoIncrementId,
    position: null,
    label: '',
    events: () => ['click'],
    delay: 0,
    disabled: false,
    adaptive: false,
    menuTransitionName: 'menu',
    matchTriggerWidth: false,
    nonInteractive: false,
    trackingEvent: undefined,
  },
);

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  closed: [];
  opened: [];
  'update:modelValue': [value: boolean];
}>();

type OptionsOverride = { placement?: Placement; delay?: number };

const mixpanel = useMixpanel();
const children: FloatingContainerContext[] = [];
const slots = useSlots();
const hasTriggerSlot = computed(() => !!slots.trigger);
let currentTrigger: TriggerEvent | null = null;
let currentOverrides: OptionsOverride | undefined;
const isMobileScreen = useIsMobileScreen();
const {
  isOpen,
  registerContentComponent,
  data,
  menuRef,
  anchorRef,
  position: currentPosition,
} = useFloatingContainerContext(props.id);

const shouldShow = computed(() => {
  const baseCondition = isOpen.value && !!data.value;
  if (anchorRef.value || (isMobileScreen.value && props.adaptive)) {
    return baseCondition;
  }

  return baseCondition && !!currentPosition.value;
});

const exposedInstance: FloatingContainerContext = {
  open,
  openAtPosition,
  openAtEventTarget,
  openAtElement,
  close,
  isOpen,
  data,
  menuRef,
  anchorRef,
  position: currentPosition,
};

registerContentComponent(exposedInstance);

function openAtPosition(pos: Position, payload: unknown, opts?: OptionsOverride) {
  currentPosition.value = pos;
  open(payload, opts);
}

function openAtEventTarget(e: Event, payload: unknown, opts?: OptionsOverride) {
  const el = e.target as HTMLElement;
  if (isOpen.value && el === anchorRef.value) {
    return;
  }

  anchorRef.value = el;
  currentTrigger = e.type === 'mouseenter' ? 'mouseenter' : 'click';
  open(payload, opts);
}

function openAtElement(el: HTMLElement, payload: unknown, opts?: OptionsOverride) {
  if (isOpen.value && el === anchorRef.value) {
    return;
  }

  anchorRef.value = el;
  currentTrigger = 'click';
  open(payload, opts);
}

// Acts as a unified source of position
const virtualElement = {
  getBoundingClientRect(): ClientRectObject {
    const base: ClientRectObject = {
      width: 0,
      height: 0,
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      x: 0,
      y: 0,
    };

    if (currentPosition.value) {
      const { x, y, width, height } = currentPosition.value;

      return {
        width: width || 0,
        height: height || 0,
        top: y,
        left: x,
        bottom: y,
        right: x,
        x,
        y,
      };
    }

    if (anchorRef.value) {
      return anchorRef.value.getBoundingClientRect();
    }

    return base;
  },
};

async function updatePosition() {
  if (!menuRef.value) {
    return;
  }

  if (props.adaptive && isMobileScreen.value) {
    menuRef.value.style.transform = `translate3d(0, 0, 0)`;
    return;
  }

  // skip if no position is calculated yet
  if (!anchorRef.value && !currentPosition.value) {
    return;
  }

  /**
   * Custom middleware to avoid clipping the viewport edges
   */
  const middleware: Middleware = {
    name: 'middleware',
    async fn(args) {
      const overflow = await detectOverflow(args);
      let diffX = 0;
      let diffY = 0;
      if (overflow.right > 0) {
        diffX = overflow.right;
      } else if (overflow.left > 0) {
        diffX = overflow.left;
      }

      if (overflow.bottom > 0) {
        diffY = overflow.bottom;
      } else if (overflow.top > 0) {
        diffY = overflow.top;
      }

      return {
        x: args.x - diffX,
        y: args.y - diffY,
      };
    },
  };

  const { x, y } = await computePosition(virtualElement, menuRef.value, {
    strategy: 'fixed',
    placement: currentOverrides?.placement || props.placement,
    middleware: [props.autoPlace && !props.adaptive ? autoPlacement() : flip(), shift({ padding: 5 }), middleware],
  });

  // if main menu position changes then child menus should be closed while that is happening
  children.forEach(c => c.close());

  // Bad calculation, should close immediately.
  if (Number.isNaN(x) || Number.isNaN(y)) {
    close();
    return;
  }

  menuRef.value.style.transform = `translate3d(${Math.round(x)}px, ${Math.round(y)}px, 0)`;
}

function updateIfOpen() {
  if (isOpen.value) {
    updatePosition();
  }
}

// if placement changes while open
watch(() => props.placement, updateIfOpen);
useResizeObserver(menuRef, updateIfOpen);
onMounted(updateAnchorEvents);

let pendingOperationTimeout: number;

function toggle() {
  if (props.disabled) {
    return;
  }

  clearPendingOperations();

  if (isOpen.value) {
    close();
    return;
  }

  open();
  if (props.trackingEvent) {
    mixpanel.track(props.trackingEvent);
  }
}

function closeWithAnchorDelay(e: Event) {
  const isAnchor = anchorRef.value ? e.composedPath().includes(anchorRef.value) : false;
  if (isAnchor) {
    pendingOperationTimeout = window.setTimeout(close, 200);
    return;
  }

  close();
}

/**
 * Handles when the mouse leaves the popup
 */
function onLeave(e: Event) {
  if (currentTrigger !== 'mouseenter') {
    return;
  }

  const relatedTarget: HTMLElement | undefined = (e as any).relatedTarget;
  if (!relatedTarget) {
    closeWithAnchorDelay(e);
    return;
  }

  // the mouse exited the trigger but still in the menu
  if (
    relatedTarget === anchorRef.value ||
    menuRef.value?.contains(relatedTarget) ||
    children.some(c => c.menuRef.value?.contains(relatedTarget))
  ) {
    return;
  }

  closeWithAnchorDelay(e);
}

function onTriggerClick() {
  if (isOpen.value && !props.toggles) {
    return;
  }

  if (!isOpen.value) {
    currentTrigger = 'click';
  }

  toggle();
}

function onTriggerMouseEnter() {
  if (props.disabled || isOpen.value) {
    return;
  }

  clearPendingOperations();

  currentTrigger = 'mouseenter';
  open();
}

const fixedTriggerHandlers = computed(() => {
  const handlers: Record<string, () => void> = {};
  if (props.events.includes('click')) {
    handlers.click = onTriggerClick;
  }

  if (props.events.includes('mouseenter')) {
    if (isMobileScreen.value) {
      handlers.click = onTriggerClick;
    } else {
      handlers.mouseenter = onTriggerMouseEnter;
    }
  }

  return handlers;
});

function updateAnchorEvents() {
  const anchorValue = anchorRef.value;
  if (!anchorValue) {
    return;
  }

  if (!isOpen.value) {
    if (currentTrigger === 'mouseenter') {
      anchorValue.removeEventListener('mouseleave', onLeave);
    }

    return;
  }

  // wait for most animations to finish
  window.setTimeout(() => {
    if (currentTrigger === 'mouseenter') {
      anchorValue.addEventListener('mouseleave', onLeave);
    }

    mountScrollParents();
  }, 301);
}

watch(
  () => anchorRef.value,
  (value, oldValue) => {
    if (value && menuRef.value && props.matchTriggerWidth) {
      const { width } = value.getBoundingClientRect();
      menuRef.value.style.width = `${width}px`;
    }

    if (oldValue) {
      oldValue.onmouseleave = null;
      oldValue.removeEventListener('mouseleave', onLeave);
    }
  },
);

watch(
  () => props.position,
  value => {
    if (isOpen.value) {
      currentPosition.value = value;
      updatePosition();
    }
  },
);

function onParentScroll() {
  if (!isOpen.value) {
    return;
  }

  requestAnimationFrame(() => {
    updatePosition();
  });
}

let scrollMounted = false;

/**
 * Mounts scroll event on parents, this should only be done once in the lifetime of the tooltip
 */
function mountScrollParents() {
  if (scrollMounted) {
    return;
  }

  const element = anchorRef.value;
  if (!element) {
    return;
  }

  getOverflowAncestors(element).forEach(el => {
    el.addEventListener('scroll', onParentScroll, { passive: true });
  });

  scrollMounted = true;
}

onBeforeUnmount(() => {
  const element = anchorRef.value;
  if (element) {
    element.onmouseleave = null;
    getOverflowAncestors(element).forEach(el => el.removeEventListener('scroll', onParentScroll));
  }
});

function addGlobalListeners() {
  document.body.addEventListener('click', onClickOutside);
  document.body.addEventListener('keydown', onKeyboardDown);
}

function removeGlobalListeners() {
  document.body.removeEventListener('click', onClickOutside);
  document.body.removeEventListener('keydown', onKeyboardDown);
}

async function close() {
  clearPendingOperations();

  currentTrigger = null;
  isOpen.value = false;
  currentPosition.value = null;
  await nextTick();
  removeGlobalListeners();
  updateAnchorEvents();
  emit('closed');
  emit('update:modelValue', false);
  // clear the anchor when closed
  if (!hasTriggerSlot.value) {
    anchorRef.value = null;
  }
}

function onEnter() {
  clearPendingOperations();
}

function clearPendingOperations() {
  if (pendingOperationTimeout) {
    window.clearTimeout(pendingOperationTimeout);
  }
}

function open(payload?: unknown, opts?: OptionsOverride) {
  clearPendingOperations();
  currentOverrides = opts;

  async function commit() {
    data.value = payload ?? data.value ?? {};
    isOpen.value = true;
    await nextTick();
    updatePosition();
    updateAnchorEvents();
    setTimeout(() => {
      addGlobalListeners();
    }, 301);
    emit('opened');
    emit('update:modelValue', true);
  }

  const delay = currentOverrides?.delay ?? props.delay;
  if (delay && !isMobileScreen.value) {
    pendingOperationTimeout = window.setTimeout(commit, delay);
    if (currentTrigger === 'mouseenter' && anchorRef.value) {
      // if the user left the trigger item before it is shown then cancel the pending op
      anchorRef.value.onmouseleave = function onPrematureLeave() {
        window.clearTimeout(pendingOperationTimeout);
      };
    }

    return;
  }

  commit();
}

function onClickOutside(e: Event) {
  if (!isOpen.value) {
    return;
  }

  const el = e.target as HTMLElement;
  if ((el && el.closest(`#FloatingContainer-${props.id}`)) || anchorRef.value?.contains(el)) {
    return;
  }

  if (children.some(c => c.anchorRef.value?.contains(el) || c.menuRef.value?.contains(el))) {
    return;
  }

  close();
}

function onKeyboardDown(e: KeyboardEvent) {
  if (e.code === 'Escape') {
    close();
  }
}

const route = useRoute();
watch(() => route.name, close);

defineExpose(exposedInstance);

// Registers sub-containers to allow the parent to control them
const injectedParent = inject(FloatingContainerParentContextKey, null);
const hasParent = !!injectedParent;

if (injectedParent) {
  injectedParent.register(exposedInstance);

  onBeforeUnmount(() => {
    injectedParent.unregister(exposedInstance);
  });
}

const parentContext: FloatingContainerParentContext = {
  id: props.id,
  register(instance) {
    injectedParent?.register(instance);
    children.push(instance);
  },
  unregister(instance) {
    injectedParent?.unregister(instance);
    const idx = children.indexOf(instance);
    if (idx !== -1) {
      children.splice(idx, 1);
    }
  },
};

provide(FloatingContainerParentContextKey, parentContext);
</script>

<style lang="postcss" scoped>
.menu-container {
  @apply z-50 fixed top-0 left-0;

  &.is-adaptive {
    @apply inset-x-0 bottom-0;
    top: unset;

    @screen desktop-sm {
      left: 0;
      top: 0;
      right: unset;
      bottom: unset;
    }
  }

  @screen desktop-sm {
    left: 0;
    right: unset;
    bottom: unset;
  }
}
</style>
