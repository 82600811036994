import { useMutation, useQuery, useSubscription } from 'villus';
import { setUser as setSentryUserContext } from '@sentry/browser';
import { ComputedRef, InjectionKey, Ref, computed, provide, ref, watch } from 'vue';
import { until } from '@vueuse/core';
import {
  CurrentUserDocument,
  CurrentUserQuery,
  UpdateCurrentUserDocument,
  UpdateCurrentUserMutationVariables,
} from '@shared/graphql/Auth';
import { useStoredRef } from '@shared/features/refs';
import { AppUserChangedDocument, AppUserSetPresenceStateDocument } from '@shared/graphql/AppUser';
import { makeHttpJsonRequest } from '@shared/utils/network';
import { AppUserPresenceEnum } from '@shared/types/graphql.gen';
import { injectWithSelf } from '@shared/utils/common';
import { AUTH_STORAGE_KEY } from '@shared/constants';
import { useAppContext } from '@shared/features/app';
import { CurrentUserFieldsFragment } from '@shared/graphql/fragments';
import { dispatchUserRoleChanged } from './appEvents';

export const CurrentAppUserInjectionKey: InjectionKey<ComputedRef<CurrentUserFieldsFragment>> = Symbol(
  'Current Authenticated User of the App',
);

export const CurrentAppUserCtxKey: InjectionKey<CurrentUserContext> = Symbol('Context for current User of the App');

/**
 * Holds a live ref to the current user
 */
const appUser = __IS_IFRAME_BUILD__
  ? ref<CurrentUserQuery['currentAppUser'] | undefined>()
  : useStoredRef<CurrentUserQuery['currentAppUser'] | undefined>(undefined, 'currentUser');

export function useTokenAuth() {
  const token = ref(localStorage.getItem(AUTH_STORAGE_KEY) || '');
  const isAuthenticated = computed(() => !!token.value);

  function setToken(newToken: string) {
    localStorage.setItem(AUTH_STORAGE_KEY, newToken);
    token.value = newToken;
  }

  return {
    token,
    setToken,
    isAuthenticated,
  };
}

export interface CurrentUserContext {
  user: Ref<CurrentUserQuery['currentAppUser']>;
  isFetching: Ref<boolean>;
  isSyncing: Ref<boolean>;
  refresh: () => Promise<void>;
}

/**
 * Fetches the current user
 */
export function useCurrentUserContext() {
  const appUserCtx = injectWithSelf(CurrentAppUserCtxKey);
  if (appUserCtx) {
    return appUserCtx;
  }

  const app = useAppContext();
  const { data, isFetching, execute } = useQuery({
    query: CurrentUserDocument,
  });

  watch(data, async newValue => {
    // Wait for app to fetch, to get fresh flag values
    if (app.isSyncing.value) {
      await until(app.isFetching).toBe(false);
    }

    const user = newValue?.currentAppUser;
    appUser.value = user;
    setSentryUserContext(user ? { id: user.id ? String(user.id) : undefined, email: user.email } : null);
  });

  const currentUser = computed(() => {
    return appUser.value;
  });

  useSubscription(
    {
      query: AppUserChangedDocument,
    },
    next => {
      const payload = next.data?.appUserChanged;
      if (!payload || payload.user.id !== currentUser.value?.id) {
        return;
      }

      const oldRoleId = appUser.value?.role.id;
      const newRoleId = payload.user.role.id;
      if (oldRoleId !== newRoleId) {
        dispatchUserRoleChanged();
      }
      appUser.value = Object.assign(appUser.value || { notificationPreferences: [] }, payload.user) as any;
    },
  );

  // Makes the app user available globally for all components
  // TODO: Avoid casting
  provide(CurrentAppUserInjectionKey, currentUser as ComputedRef<CurrentUserFieldsFragment>);

  const ctx: CurrentUserContext = {
    user: appUser as Ref<CurrentUserQuery['currentAppUser']>,
    refresh: async () => {
      await execute();
    },
    isFetching: computed(() => !appUser.value && isFetching.value),
    isSyncing: isFetching,
  };

  provide(CurrentAppUserCtxKey, ctx);

  return ctx;
}

/**
 * Updates the user profile
 */
export function useUpdateProfile() {
  const { execute, isFetching: isUpdating } = useMutation(UpdateCurrentUserDocument);

  /**
   * Updates a user profile
   */
  async function update(input: UpdateCurrentUserMutationVariables['input']) {
    const { data, error } = await execute({ input });
    if (data?.response) {
      appUser.value = Object.assign(appUser.value || { notificationPreferences: [] }, data.response);
    }

    if (error) {
      throw error;
    }
  }

  return {
    update,
    isUpdating,
  };
}

export function useSetCurrentUserPresence() {
  const { execute, isFetching: isSetting } = useMutation(AppUserSetPresenceStateDocument);
  const { refresh } = useCurrentUserContext();

  async function setStatus(status: AppUserPresenceEnum) {
    if (!appUser.value) {
      throw new Error('App user not loaded');
    }

    appUser.value.presenceStatus = status;
    const { data, error } = await execute({
      input: {
        id: appUser.value.id,
        presence: status,
      },
    });

    if (data?.response) {
      refresh();
    }

    if (error) {
      throw error;
    }
  }

  return {
    isSetting,
    setStatus,
  };
}

export function useLogout() {
  const isLoggingOut = ref<boolean>(false);

  async function logout() {
    isLoggingOut.value = true;
    try {
      localStorage.removeItem(AUTH_STORAGE_KEY);
      // await clearPushNotificationState();
      await makeHttpJsonRequest(
        `${import.meta.env.API_URL || ''}/logout`,
        {
          authenticity_token: document.querySelector('meta[name=csrf-token]')?.getAttribute('content') || '',
        },
        'post',
      );
    } catch (err) {
    } finally {
      if (__IS_NATIVE_MOBILE__) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
    }
  }

  return {
    logout,
    isLoggingOut,
  };
}
