import * as Types from '../types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', currentAppUser: { __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, notificationPreferences: Array<{ __typename?: 'AppUserNotificationPreference', notificationMethod: Types.AppUserNotificationMethodEnum, notificationTypes: Array<Types.AppUserNotificationTypeEnum> }>, role: { __typename?: 'Role', permissions: Array<Types.AppUserRolePermissionEnum>, isAdmin: boolean, id: number, name: string, emoji: string } } };

export type UpdateCurrentUserMutationVariables = Types.Exact<{
  input: Types.AppUserUpdateInput;
}>;


export type UpdateCurrentUserMutation = { __typename?: 'Mutation', response?: { __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, notificationPreferences: Array<{ __typename?: 'AppUserNotificationPreference', notificationMethod: Types.AppUserNotificationMethodEnum, notificationTypes: Array<Types.AppUserNotificationTypeEnum> }>, role: { __typename?: 'Role', permissions: Array<Types.AppUserRolePermissionEnum>, isAdmin: boolean, id: number, name: string, emoji: string } } | null };

export type AppUserDeviceTokenRegisterMutationVariables = Types.Exact<{
  input: Types.AppUserDeviceTokenRegisterInput;
}>;


export type AppUserDeviceTokenRegisterMutation = { __typename?: 'Mutation', response?: { __typename?: 'AppUserDeviceTokenRegisterPayload', success: boolean } | null };

export declare const CurrentUser: import("graphql").DocumentNode;
export declare const UpdateCurrentUser: import("graphql").DocumentNode;
export declare const AppUserDeviceTokenRegister: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const CurrentUserDocument = new TypedDocumentString(`
    query CurrentUser {
  currentAppUser {
    ...CurrentUserFields
  }
}
    fragment CurrentUserFields on AppUser {
  ...UserInfo
  notificationPreferences {
    notificationMethod
    notificationTypes
  }
  role: userRole {
    permissions
    isAdmin
  }
}
fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<CurrentUserQuery, CurrentUserQueryVariables>;
export const UpdateCurrentUserDocument = new TypedDocumentString(`
    mutation UpdateCurrentUser($input: AppUserUpdateInput!) {
  response: appUserUpdate(input: $input) {
    ...CurrentUserFields
  }
}
    fragment CurrentUserFields on AppUser {
  ...UserInfo
  notificationPreferences {
    notificationMethod
    notificationTypes
  }
  role: userRole {
    permissions
    isAdmin
  }
}
fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<UpdateCurrentUserMutation, UpdateCurrentUserMutationVariables>;
export const AppUserDeviceTokenRegisterDocument = new TypedDocumentString(`
    mutation AppUserDeviceTokenRegister($input: AppUserDeviceTokenRegisterInput!) {
  response: appUserDeviceTokenRegister(input: $input) {
    success
  }
}
    `) as unknown as TypedDocumentString<AppUserDeviceTokenRegisterMutation, AppUserDeviceTokenRegisterMutationVariables>;