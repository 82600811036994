<template>
  <div
    class="InputCheckbox"
    :class="[
      `size-${size}`,
      { dark, 'has-error': !!errorMessage, validatable: novalidate, undetermined },
      $attrs.class,
    ]"
    @click="onChange"
  >
    <slot name="before"></slot>

    <div class="InputCheckbox__Control">
      <input
        :id="name"
        :name="name"
        type="checkbox"
        :checked="checked"
        :disabled="disabled"
        :value="value"
        tabindex="-1"
        v-bind="inputAttrs"
        @change="onChange"
        @blur="handleBlur"
      />

      <button
        class="InputCheckbox__Control__btn"
        :disabled="disabled"
        :aria-pressed="checked && !undetermined ? 'true' : 'false'"
        type="button"
      >
        <PhMinus v-if="undetermined" class="w-3.5 h-3.5" :class="{ invisible: !checked }" />
        <PhCheck v-else class="text-white w-3.5 h-3.5" :class="{ invisible: !checked }" />
      </button>
    </div>

    <slot name="label">
      <label v-if="label" :for="name" class="ml-2 block text-sm size-sm:text-xs text-gray-800 select-none">
        {{ label }}
      </label>
    </slot>

    <span v-if="!novalidate && errorMessage" class="InputCheckbox__Error">{{ errorMessage }}</span>
  </div>
</template>

<script lang="ts" setup>
import { useField } from 'vee-validate';
import { omit } from 'lodash-es';
import { useAttrs, inject, computed, watch } from 'vue';
import { TableCheckboxContextKey } from '@shared/injectionKeys';

export type InputCheckboxSize = 'sm' | 'base' | 'lg';

defineOptions({
  inheritAttrs: false,
});

const props = withDefaults(
  defineProps<{
    modelValue?: any;
    value?: any;
    uncheckedValue?: any;
    label?: string;
    name: string;
    disabled?: boolean;
    novalidate?: boolean;
    size?: InputCheckboxSize;
    standalone?: boolean;
    undetermined?: boolean;
    dark?: boolean;
  }>(),
  {
    modelValue: undefined,
    size: 'base',
    label: '',
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: any): void;
}>();

const attrs = useAttrs();

const inputAttrs = computed(() => {
  return omit(attrs, 'class');
});

const { errorMessage, value, handleChange, handleBlur, checked } = useField(props.name, undefined, {
  initialValue: props.modelValue || undefined,
  type: 'checkbox',
  checkedValue: props.value,
  uncheckedValue: props.uncheckedValue,
  standalone: props.standalone,
  syncVModel: false,
});

const checkboxTableContext = inject(TableCheckboxContextKey, null);

function onChange() {
  if (props.disabled) {
    return;
  }

  if (props.undetermined) {
    emit('update:modelValue', undefined);

    return;
  }

  handleChange(props.value);
  checkboxTableContext?.checkboxToggled(props.value);
  emit('update:modelValue', value.value);
}

defineExpose({
  toggle: onChange,
});

watch(
  () => props.modelValue,
  newValue => {
    value.value = newValue;
  },
);
</script>

<style lang="postcss" scoped>
.InputCheckbox {
  @apply inline-flex items-center relative;
  &.validatable {
    /** Gives a breathing room to prevent error messages from "popping" and shifting layout */
    padding-bottom: 1.5rem;
  }

  &__Control {
    @apply w-5 h-5;

    input {
      @apply hidden;
    }

    &__btn {
      @apply w-5 h-5 border border-gray-200 rounded transition-colors duration-150 focus:outline-none pressed:bg-blue-600 pressed:border-blue-600 text-white flex items-center justify-center focus:border-blue-600;

      &:disabled {
        @apply cursor-not-allowed pressed:bg-gray-300 pressed:border-gray-300;
      }
    }
  }

  &.undetermined {
    .InputCheckbox__Control {
      &__btn {
        @apply border-gray-300 hover:border-gray-400 text-gray-400;
      }
    }
  }

  &.has-error {
    .InputCheckbox__Control {
      input {
        @apply text-red-600 focus:ring-red-500 border-red-300;
      }
    }
  }

  &.dark {
    &.undetermined {
      .InputCheckbox__Control {
        &__btn {
          @apply border-white hover:border-gray-100 text-white;
        }
      }
    }
  }

  &__Error {
    @apply absolute bottom-0 left-0 text-sm text-red-600;
  }

  &.size-lg {
    input,
    .InputCheckbox__Control,
    .InputCheckbox__Control__btn {
      @apply w-6 h-6;
    }
  }

  &.size-sm {
    input,
    .InputCheckbox__Control,
    .InputCheckbox__Control__btn {
      @apply w-[18px] h-[18px];
    }
  }
}
</style>
