<template>
  <div
    class="InputText flex flex-col"
    v-bind="classAttr"
    :class="[
      `display-${display}`,
      `size-${size}`,
      `is-${variant}`,
      {
        'has-error': !!errorMessage && meta.touched,
        validatable: variant !== 'inline' && !noValidate,
      },
    ]"
  >
    <label
      v-if="label && variant !== 'inline'"
      :for="name"
      class="mb-1 text-gray-800 text-sm leading-5 font-normal flex flex-col items-start"
    >
      <span class="flex items-center">
        <slot name="beforeLabel"></slot>

        {{ label }}
      </span>

      <slot name="afterLabel"></slot>
    </label>

    <div
      v-if="type !== 'textarea'"
      class="relative flex items-center InputText__ControlWrapper"
      :aria-disabled="disabled ? 'true' : 'false'"
    >
      <input
        :id="name"
        :name="name"
        :readonly="readonly"
        :disabled="disabled"
        :value="value"
        :type="type"
        class="InputText__Control"
        v-bind="$attrs"
        @paste="onPaste"
        v-on="listeners"
      />

      <div class="InputText__Icons">
        <PhWarningFill
          v-if="errorMessage && meta.touched"
          v-tooltip="errorMessage && variant === 'inline' ? errorMessage : ''"
          name="exclamation"
          aria-hidden="true"
          class="InputText__ErrorIcon"
        />

        <slot name="afterInput" />
      </div>
    </div>

    <div v-else class="InputText__ControlWrapper">
      <textarea
        :id="name"
        v-model="value"
        :name="name"
        :readonly="readonly"
        :disabled="disabled"
        class="InputText__Control"
        :rows="5"
        v-bind="$attrs"
        v-on="listeners"
        @paste="onPaste"
      >
      </textarea>
    </div>

    <span v-if="variant !== 'inline' && errorMessage && meta.touched && !noValidate" class="InputText__Error">{{
      errorMessage
    }}</span>
    <span v-if="maxLength !== undefined && !errorMessage && variant !== 'inline' && value" class="InputText__Length">
      {{ Math.abs((value || '').length - maxLength) }} characters remaining
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, toRef, watch } from 'vue';
import { useField } from 'vee-validate';
import { useSplitAttrs } from '@shared/features/refs';

export type InputTextVariant = 'inline' | 'default' | 'inline-bg';
export type InputTextSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: null,
    default: undefined,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Toggles passive validation mode (validates on submit and input)
   */
  passive: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<InputTextSize>,
    default: 'base',
  },
  variant: {
    type: String as PropType<InputTextVariant>,
    default: 'default',
  },
  noValidate: {
    type: Boolean,
    default: false,
  },
  uncontrolled: {
    type: Boolean,
    default: false,
  },
  validation: {
    type: null,
    default: undefined,
  },
  display: {
    type: String as PropType<'block' | 'inline'>,
    default: 'block',
  },
});

const emit = defineEmits<{
  (e: 'paste', evt: ClipboardEvent): void;
  (e: 'input', value: string | undefined): void;
  (e: 'update:modelValue', value: any): void;
}>();

const modelValue = toRef(props, 'modelValue');
const fieldName = toRef(props, 'name');
const validationSchema = toRef(props, 'validation');
const [attrs, classAttr] = useSplitAttrs('class');

const { errorMessage, value, handleChange, handleBlur, meta, validate, setTouched } = useField<string | undefined>(
  fieldName,
  validationSchema,
  {
    initialValue: modelValue.value,
    validateOnValueUpdate: false,
    standalone: props.uncontrolled,
    syncVModel: false,
  },
);

const maxLength = computed(() => {
  return attrs.value.maxlength ? Number(attrs.value.maxlength) : undefined;
});

function handleInput(e: Event) {
  handleChange(e, false);
  emit('input', value.value);
}

const listeners = computed(() => {
  if (errorMessage.value) {
    return {
      input: handleChange,
      blur: onBlur,
    };
  }

  return {
    input: handleInput,
    change: handleChange,
    blur: onBlur,
  };
});

// Syncs external value changes
watch(modelValue, newValue => {
  if (value.value !== newValue) {
    value.value = newValue;
  }
});

watch(value, newValue => {
  if (value.value !== modelValue.value) {
    emit('update:modelValue', newValue);
  }
});

function onBlur(e: Event) {
  handleBlur();
  if (!props.passive) {
    handleChange(e);
  }
}

function onPaste(e: ClipboardEvent) {
  emit('paste', e);
}

defineOptions({
  inheritAttrs: false,
});

defineExpose({
  validate() {
    setTouched(true);
    validate();
  },
});
</script>

<style lang="postcss" scoped>
.InputText {
  @apply w-full block relative;

  &.display-inline {
    @apply inline-block w-auto min-w-[230px];
  }

  /** Gives a breathing room to prevent error messages from "popping" and shifting layout */
  &.validatable {
    padding-bottom: 1.5rem;
  }

  &__ControlWrapper {
    @apply border border-gray-200 rounded-md hover:border-gray-400 disabled:border-gray-200 disabled:bg-gray-50 shadow-sm bg-white;

    &:focus-within {
      @apply border-blue-500;
    }
  }

  &__Control {
    @apply block w-full text-gray-800 rounded-md placeholder-gray-400 transition-colors duration-300 py-2 px-4 focus:outline-none text-sm bg-transparent;

    &:disabled {
      @apply text-gray-500 cursor-not-allowed;
    }
  }

  &__Error {
    @apply absolute bottom-0.5 left-0 text-red-600 text-xs leading-4 font-medium;
  }

  &__Icons {
    @apply absolute flex items-center gap-0.5;
    right: 0.5rem;
  }

  &__Length {
    @apply hidden absolute bottom-0.5 left-0 text-gray-500 text-xs leading-4 font-normal;
  }

  &:focus-within {
    .InputText__Length {
      @apply block;
    }
  }

  &__ErrorIcon {
    @apply w-5 h-5 text-red-600;
  }

  &.size-xs {
    label {
      @apply text-xs;
    }

    .InputText__Control {
      @apply text-xs px-2 py-1.5;
    }

    &.validatable {
      @screen desktop-sm {
        padding-bottom: 1.2rem;
      }
    }
  }

  &.size-sm {
    &.validatable {
      @screen desktop-sm {
        padding-bottom: 1.2rem;
      }
    }

    .InputText__Control {
      @apply text-xs;
    }
  }

  &.size-lg {
    .InputText__Control {
      @apply text-sm leading-5 font-normal py-3 px-4;
    }
  }

  &.size-xl {
    .InputText__Control {
      @apply text-lg leading-6 font-normal px-4 py-3;
    }
  }

  &.size-2xl {
    .InputText__Control {
      @apply text-lg leading-7 font-normal px-4 py-5;
    }
  }

  &.is-inline {
    .InputText__ControlWrapper {
      @apply border border-transparent focus-within:border-transparent hover:bg-gray-50 disabled:bg-transparent shadow-none;

      &:focus-within {
        @apply bg-gray-100;
      }
    }

    .InputText__Control {
      @apply shadow-none;
    }

    padding-bottom: 0;
  }
  &.is-inline-bg {
    .InputText__ControlWrapper {
      @apply border border-transparent focus-within:border-transparent bg-gray-50 hover:bg-gray-100 disabled:bg-transparent shadow-none;

      &:focus-within {
        @apply bg-gray-100;
      }
    }

    .InputText__Control {
      @apply shadow-none;
    }

    padding-bottom: 0;
  }

  &.has-error {
    .InputText__ControlWrapper {
      @apply border-red-300 focus-within:border-red-400 focus-within:ring-red-400;
    }

    .InputText__Control {
      @apply text-red-700 pr-8;
    }

    &.is-inline {
      .InputText__ControlWrapper {
        @apply bg-yellow-50 border border-transparent;
      }

      .InputText__Control {
        @apply text-gray-800;
      }
    }
  }
}
</style>
