import * as Types from '../types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type AppUserChangedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type AppUserChangedSubscription = { __typename?: 'Subscription', appUserChanged: { __typename?: 'appUserChangedPayload', action: Types.MutationActionEnum, user: { __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, role: { __typename?: 'Role', id: number, name: string, emoji: string, isAdmin: boolean } } } };

export type AppUserSetPresenceStateMutationVariables = Types.Exact<{
  input: Types.AppUserSetPresenceStateInput;
}>;


export type AppUserSetPresenceStateMutation = { __typename?: 'Mutation', response?: { __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, role: { __typename?: 'Role', id: number, name: string, emoji: string, isAdmin: boolean } } | null };

export type AppUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppUsersQuery = { __typename?: 'Query', app: { __typename?: 'App', appUsers: { __typename?: 'AppUserConnection', nodes: Array<{ __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, role: { __typename?: 'Role', id: number, name: string, emoji: string, isAdmin: boolean } }> } } };

export type AppUserPresenceUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type AppUserPresenceUpdatedSubscription = { __typename?: 'Subscription', appUserPresenceUpdated: { __typename: 'AppUser', id: number, email: string, fullName?: string | null, displayName?: string | null, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, presenceStatus: Types.AppUserPresenceEnum, inActiveTeam: boolean, status: Types.AppUserStatusEnum, createdAt: number, role: { __typename?: 'Role', id: number, name: string, emoji: string, isAdmin: boolean } } };

export declare const AppUserChanged: import("graphql").DocumentNode;
export declare const AppUserSetPresenceState: import("graphql").DocumentNode;
export declare const AppUsers: import("graphql").DocumentNode;
export declare const AppUserPresenceUpdated: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const AppUserChangedDocument = new TypedDocumentString(`
    subscription AppUserChanged {
  appUserChanged {
    action
    user {
      ...UserInfo
    }
  }
}
    fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<AppUserChangedSubscription, AppUserChangedSubscriptionVariables>;
export const AppUserSetPresenceStateDocument = new TypedDocumentString(`
    mutation AppUserSetPresenceState($input: AppUserSetPresenceStateInput!) {
  response: appUserSetPresenceState(input: $input) {
    ...UserInfo
  }
}
    fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<AppUserSetPresenceStateMutation, AppUserSetPresenceStateMutationVariables>;
export const AppUsersDocument = new TypedDocumentString(`
    query AppUsers {
  app {
    appUsers {
      nodes {
        ...UserInfo
      }
    }
  }
}
    fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<AppUsersQuery, AppUsersQueryVariables>;
export const AppUserPresenceUpdatedDocument = new TypedDocumentString(`
    subscription AppUserPresenceUpdated {
  appUserPresenceUpdated {
    ...UserInfo
  }
}
    fragment UserInfo on AppUser {
  id
  __typename
  email
  fullName
  displayName
  avatarUrl
  optimisedAvatarUrl
  presenceStatus
  inActiveTeam
  role: userRole {
    id
    name
    emoji
    isAdmin
  }
  status
  createdAt
}`) as unknown as TypedDocumentString<AppUserPresenceUpdatedSubscription, AppUserPresenceUpdatedSubscriptionVariables>;