<template>
  <svg
    class="flex-shrink-0"
    :class="{
      '-rotate-90': !undetermined,
      'animate-spin': undetermined,
      [`size-${size || 'base'}`]: true,
    }"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      :stroke-width="circleStyle['stroke-width']"
    ></circle>
    <circle cx="12" cy="12" r="10" stroke="currentColor" :style="circleStyle"></circle>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
  value?: number;
  undetermined?: boolean;
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
}>();

const circleStyle = computed(() => {
  const circumference = Math.ceil(2 * Math.PI * 10);
  if (props.undetermined) {
    return {
      'stroke-dasharray': circumference,
      'stroke-dashoffset': Math.ceil(0.7 * circumference),
      'stroke-width': '2',
    };
  }

  const value = 1 - (props.value || 1);

  return {
    'stroke-dasharray': circumference,
    'stroke-dashoffset': value < 0 ? 1 : Math.ceil(value * circumference),
    'stroke-width': '2',
  };
});
</script>

<style lang="postcss" scoped>
.size-xs {
  @apply w-3.5 h-3.5;
}

.size-sm {
  width: 16px;
  height: 16px;
}

.size-base {
  width: 18px;
  height: 18px;
}

.size-lg {
  width: 24px;
  height: 24px;
}

.size-xl {
  width: 32px;
  height: 32px;
}
</style>
