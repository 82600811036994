<template>
  <time :datetime="isoDate">{{ displayDate }}</time>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { format as formatDate, formatISO } from 'date-fns';

const props = defineProps<{
  timestamp: number;
  format: string | ((timestamp: number) => string);
}>();

const isoDate = computed(() => {
  return formatISO(props.timestamp);
});

const displayDate = computed(() => {
  if (typeof props.format === 'function') {
    return props.format(props.timestamp);
  }

  return formatDate(props.timestamp, props.format);
});
</script>
