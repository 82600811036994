<script lang="ts">
import { defineComponent } from 'vue';
import { useIsAppFeatureEnabled } from '@shared/features/featureFlags';

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isEnabled = useIsAppFeatureEnabled(() => props.name);

    return {
      isEnabled,
    };
  },
});
</script>

<template>
  <slot v-if="isEnabled" />
  <slot v-else name="disabled" />
</template>
