import { marked } from 'marked';
import emojiRegex from 'emoji-regex';
import { UserInfoFragment } from '@shared/graphql/fragments';
import { LastMessagePreview, Maybe, StreamInternalEventMessage, StreamMessage } from '@shared/types';
import colors from '@shared/data/colors.json';
import stringify from 'fast-json-stable-stringify';
import { MessageSubTypeEnum } from '@shared/types/graphql.gen';
import { BotUserFieldsFragment } from '@shared/graphql/App';
import { useAppUsers } from '@shared/features/users';
import { useCurrentUserContext } from '@shared/features/currentUser';
import { useAppBotUsersContext } from '@shared/features/app';

/**
 * Copies the provided string to the clipboard
 */
export function copyToClipboard(str: string): void {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const rangeCount = document.getSelection()?.rangeCount || 0;
  const selected =
    rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection()?.getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection()?.removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection()?.addRange(selected); // Restore the original selection
  }
}

export function pluralize(count: number, singular: string, plural?: string) {
  plural = plural || `${singular}s`;

  return count === 1 ? singular : plural;
}

export interface UserInfo {
  name: string | null;
  firstName: string | null;
  fullName: string | null;
  lastName: string | null;
  displayName: string | null;
  phone: string | null;
}

/**
 * Uses the user info provided to generate appropriate text representation of the user
 */
export function presentUserString(info: Maybe<Partial<UserInfo>>, fallback = 'Unknown Contact') {
  if (!info) {
    return fallback;
  }

  if (info.name) {
    return info.name;
  }

  if (info.fullName) {
    return info.fullName;
  }

  if (info.firstName) {
    return `${info.firstName}${info.lastName ? ' ' + info.lastName : ''}`;
  }

  if (info.displayName) {
    return info.displayName;
  }

  if (info.phone) {
    return info.phone;
  }

  return fallback;
}

/**
 * Uses the user info provided to generate appropriate short text representation of the user
 */
export function presentUserShortString(info: Maybe<Partial<UserInfo>>, fallback = 'Unknown'): string {
  if (!info) {
    return fallback;
  }

  if (info.name) {
    return info.name;
  }

  if (info.fullName) {
    return info.fullName.trim().split(' ').shift() as string;
  }

  if (info.firstName) {
    return info.firstName;
  }

  if (info.lastName) {
    return info.lastName;
  }

  if (info.displayName) {
    return info.displayName;
  }

  if (info.phone) {
    return info.phone;
  }

  return fallback;
}

/**
 * Creates a string describing a conversation event
 */
function resolveEventDescription(eventType: MessageSubTypeEnum | 'SNOOZE' | 'UNSNOOZE') {
  if (eventType === 'SYSTEM_CLOSE') {
    return 'closed this conversation';
  }

  if (eventType === 'SYSTEM_TERMINATE') {
    return 'permanently closed this conversation';
  }

  if (eventType === 'SYSTEM_OPEN') {
    return 'opened this conversation';
  }

  if (eventType === 'SYSTEM_WAITING') {
    return 'set this conversation to waiting';
  }

  if (eventType === 'SYSTEM_BLOCK') {
    return 'set this conversation to blocked';
  }

  if (eventType === 'SNOOZE') {
    return 'snoozed this conversation';
  }

  if (eventType === 'UNSNOOZE') {
    return 'unsnoozed this conversation';
  }

  if (eventType === 'SYSTEM_CAMPAIGN_WORKFLOW_BOT_CONTROLLED') {
    return 'is managing this conversation as part of a campaign';
  }

  if (eventType === 'SYSTEM_PROACTIVE_WORKFLOW_BOT_CONTROLLED') {
    return 'is managing this conversation';
  }

  if (eventType === 'SYSTEM_WORKFLOW_BOT_CONTROLLED') {
    return 'is managing this conversation';
  }

  if (eventType === 'SYSTEM_SEQUENCE_CONTROLLED') {
    return 'is managing this conversation';
  }

  return 'performed unknown action';
}

export function useMessageAsTextPresenter() {
  const { user: currentUser } = useCurrentUserContext();
  const { lookup: findAppUser } = useAppUsers();
  const { findBotUser } = useAppBotUsersContext();

  return function presentMessage(message: StreamMessage | LastMessagePreview) {
    return presentMessageNode(message, currentUser.value, findAppUser, findBotUser);
  };
}

/**
 * Creates an object representing a message stream node and its content
 */
function presentMessageNode(
  message: StreamMessage | LastMessagePreview,
  currentUser: UserInfoFragment,
  lookupUser: (id: number) => UserInfoFragment | undefined,
  lookupBotUser: (id: number) => BotUserFieldsFragment | undefined,
) {
  if (!('user' in message)) {
    if (message.eventType === 'ASSIGNMENT') {
      return presentAssignmentMessage(message, currentUser, lookupUser, lookupBotUser);
    }

    return { actor: '', body: '' };
  }

  let actor = message.user.id === currentUser.id ? 'You' : presentUserString(message.user);
  if (message.user.type === 'BotUser') {
    actor = 'Bot user';
  }
  if (message.type === 'INTERNAL') {
    const description = resolveEventDescription('eventType' in message ? message.eventType : message.subType);

    return { actor, body: description };
  }

  if (message.type === 'DELETED') {
    return { actor, body: 'Deleted message' };
  }

  if ('body' in message) {
    if (!('files' in message) && message.type === 'NOTE' && !message.body) {
      return {
        actor,
        body: 'attached a file',
      };
    }
    if (
      !('files' in message) &&
      (message.type === 'IMAGE' || message.type === 'AUDIO' || message.type === 'VIDEO' || message.type === 'DOCUMENT')
    ) {
      return {
        actor,
        body: message.type.toLowerCase(),
      };
    }

    return { actor, body: markdownToEscapedHTML(message.body || '') };
  }

  if ('files' in message && message.files) {
    if (message.files.length === 1) {
      return {
        actor,
        body: message.caption ? markdownToEscapedHTML(message.caption) : message.type.toLowerCase(),
      };
    }

    return { actor, body: message.caption ? markdownToEscapedHTML(message.caption) : 'Multiple files' };
  }

  // show the caption or fallback to a text representation of its type
  return { actor, body: message.type.toLowerCase() };
}

function presentAssignmentMessage(
  message: StreamInternalEventMessage & { eventType: 'ASSIGNMENT' },
  currentUser: UserInfoFragment,
  lookupUser: (id: number) => UserInfoFragment | undefined,
  lookupBotUser: (id: number) => BotUserFieldsFragment | undefined,
) {
  const assigner = lookupUser(message.assigner.id);
  const assignee = message.assigneeId ? lookupUser(message.assigneeId) : null;
  let actor = assigner ? (assigner.id === currentUser.id ? 'You' : presentUserString(assigner, assigner?.email)) : '';
  if (message.assigner.type === 'BotUser') {
    const bot = lookupBotUser(message.assigner.id);
    actor = bot ? `${bot.displayName}` : 'Unknown Bot';
  }

  const assigneeText = assignee
    ? assignee.id === currentUser.id
      ? actor === 'You'
        ? 'yourself'
        : 'you'
      : presentUserString(assignee, assignee.email)
    : '';

  if (assigneeText && actor) {
    return { actor, body: `assigned ${assigneeText} to this conversation` };
  }

  if (assigneeText && !actor) {
    return { actor: '', body: `Assigned ${assigneeText} to this conversation` };
  }

  if (!assigneeText && actor) {
    return { actor, body: `cleared assignment on this conversation` };
  }

  return { actor: '', body: `Assignment cleared` };
}

const emojiRegExp = emojiRegex();

export function firstCharacter(str: string) {
  // Extract the non-emoji part of the string
  let [namePart] = str.split(emojiRegExp).filter(Boolean);
  namePart = namePart?.trim() || '';

  // if no such part exists, get the first emoji in the string
  if (!namePart) {
    const [firstEmoji] = str.match(emojiRegExp) || [];

    return firstEmoji || '?';
  }

  // return the first character of name part
  return namePart.trim()[0];
}

export function markdownToEscapedHTML(md: string) {
  return marked(md.replace(/\n/g, ' ')).replace(/(<([^>]+)>)/gi, '');
}

function hashCode(str: string): number {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }

  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0;
  }

  return hash;
}

export function generateColorPairFromName(
  name: string,
  contrast: 'dark' | 'light',
): { background: string; color: string } {
  if (!name) {
    return {
      background: colors.blue[200],
      color: colors.blue[500],
    };
  }

  const red = (hashCode(name) & 0xff0000) >> 16;
  const green = (hashCode(name) & 0x00ff00) >> 8;
  const blue = hashCode(name) & 0x0000ff;

  const darkLightness = 0.1;
  const lightLightness = 0.7;

  const adjustedRedDark = Math.floor((1 - darkLightness) * red + darkLightness * 255);
  const adjustedGreenDark = Math.floor((1 - darkLightness) * green + darkLightness * 255);
  const adjustedBlueDark = Math.floor((1 - darkLightness) * blue + darkLightness * 255);

  const adjustedRedLight = Math.floor((1 - lightLightness) * red + lightLightness * 255);
  const adjustedGreenLight = Math.floor((1 - lightLightness) * green + lightLightness * 255);
  const adjustedBlueLight = Math.floor((1 - lightLightness) * blue + lightLightness * 255);

  const darkColorCode = `#${adjustedRedDark.toString(16).padStart(2, '0')}${adjustedGreenDark
    .toString(16)
    .padStart(2, '0')}${adjustedBlueDark.toString(16).padStart(2, '0')}`;

  const lightColorCode = `#${adjustedRedLight.toString(16).padStart(2, '0')}${adjustedGreenLight
    .toString(16)
    .padStart(2, '0')}${adjustedBlueLight.toString(16).padStart(2, '0')}`;

  const color = contrast === 'dark' ? '#fff' : darkColorCode;
  const background = contrast === 'dark' ? darkColorCode : lightColorCode;

  return {
    background,
    color,
  };
}

export function generateWhatsAppColorPairFromName(name: string) {
  const { green, brown, blue, red, yellow, purple } = colors.wa;
  const pairs = [
    [green['400'], '#fff'],
    [green['800'], '#fff'],
    [green['100'], '#fff'],
    [green['50'], green['400']],
    [brown['100'], green['400']],
    [purple, '#fff'],
    [yellow, green['800']],
    [red, '#fff'],
    [blue, green['800']],
  ];

  let hash = 0;
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const pairIdx = Math.abs(hash % (pairs.length - 1));
  const colorGroup = pairs[pairIdx];

  return {
    background: colorGroup[0],
    color: colorGroup[1],
  };
}

export function serializeValue<T>(filters: T): string {
  return window.btoa(stringify(filters));
}

export function deserializeValue<T>(filters: string): T | undefined {
  try {
    return JSON.parse(window.atob(filters));
  } catch (err) {
    console.warn(err);

    return undefined;
  }
}

/*
 * Capitalize first letter only
 */
export function capitalize(value: string) {
  if (!value) {
    return '';
  }

  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Converts a camelCase string to words separated by spaces and capitalized
 * @param value The camelCase string to convert
 * @returns The converted string
 */
export function camelCaseToWords(value?: string) {
  if (!value) {
    return '';
  }

  return value.replace(/([A-Z])/g, ' $1').trim();
}
