import { App } from 'vue';
import { contentDirective } from '@shared/directives/content';
import { autofocusDirective } from '../../shared/src/directives/autofocus';
import { tooltipDirective } from './directives/tooltip';
import Feature from '@shared/components/Feature.vue';
import Paywall from '@web/components/Paywall.vue';
import Timestamp from '@shared/components/Timestamp.vue';
import BaseButton from '@shared/components/BaseButton.vue';
import BaseIconButton from '@shared/components/BaseIconButton.vue';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import WebAppOnly from '@shared/components/WebAppOnly.vue';
import NativeAppOnly from '@shared/components/NativeAppOnly.vue';
import KeyboardKey from '@web/components/KeyboardKey.vue';
import Restricted from '@shared/components/Restricted.vue';

/**
 * Registers global components and base components needed around the App
 */
export function registerComponents(app: App) {
  app.directive('content', contentDirective);
  app.directive('autofocus', autofocusDirective);
  app.directive('tooltip', tooltipDirective);
  app.component('BaseButton', BaseButton);
  app.component('BaseAvatar', BaseAvatar);
  app.component('BaseIconButton', BaseIconButton);
  app.component('Feature', Feature);
  app.component('Paywall', Paywall);
  app.component('Timestamp', Timestamp);
  app.component('WebAppOnly', WebAppOnly);
  app.component('NativeAppOnly', NativeAppOnly);
  app.component('KeyboardKey', KeyboardKey);
  app.component('Restricted', Restricted);
}
