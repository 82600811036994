import { computed, ref, isRef, Ref, unref, watch, toRaw, customRef, MaybeRefOrGetter, toValue, useAttrs } from 'vue';
import FuzzySearch from 'fuzzy-search';
import { get, set, del } from 'idb-keyval';
import { captureMessage } from '@sentry/browser';
import { LazyOrRefOrRaw } from '@shared/types';
import { isCallable } from '@shared/utils/assertions';
import { omit, pick } from 'lodash-es';

/**
 * Gives a searchable array with fuzzy search
 */
export function useFuzzySearchableList<TElement extends string | Record<string, any>>(
  list: MaybeRefOrGetter<TElement[]>,
  searchQuery: MaybeRefOrGetter<string>,
  searchKeys: string[],
) {
  let searcher = new FuzzySearch(toValue(list), searchKeys);

  if (isRef(list)) {
    watch(list, newVal => {
      searcher = new FuzzySearch(newVal, searchKeys, { sort: true });
    });
  }

  const results = computed<TElement[]>(() => {
    // important to reference the underlying value
    // to add it as a dependency, otherwise this computed property won't run
    unref(list);

    return searcher.search(toValue(searchQuery));
  });

  return {
    results,
    searchQuery,
  };
}

export function useLazySearchableList<TElement extends string | Record<string, any>>(
  list: MaybeRefOrGetter<TElement[]>,
  searchKeys: string[],
) {
  let searcher = new FuzzySearch(toValue(list), searchKeys);

  watch(
    () => toValue(list),
    newVal => {
      searcher = new FuzzySearch(newVal, searchKeys, { sort: true });
    },
  );

  function search(query: string) {
    return searcher.search(toValue(query));
  }

  return search;
}

type StoredRef<T> = Ref<T> & { initialized: Ref<boolean> };

export function useStoredRef<T>(value: T, key: string) {
  const wrapper: StoredRef<T> = ref<T>(value) as unknown as StoredRef<T>;
  const initialized = ref(false);

  get<T>(key)
    .then(cachedValue => {
      // If no other process updated the ref we should update it with our cached value
      // we have to use `toRaw` because `wrapper.value` is a Proxy object and not the real underlying value
      if (toRaw(wrapper.value) === value && cachedValue !== undefined) {
        wrapper.value = cachedValue;
      }
    })
    .catch(err => {
      captureMessage(
        'Could not write to IDB, maybe the customer is using incognito or does not allow Rasayel to write to their device',
        {
          contexts: {
            error: {
              message: err.message,
            },
          },
        },
      );
    })
    .then(() => {
      initialized.value = true;
    });

  watch(
    wrapper,
    async function syncRefValue(newValue) {
      if (newValue === undefined) {
        await del(key);
        return;
      }

      try {
        // Sync the new value to the idb storage
        await set(key, JSON.parse(JSON.stringify(newValue)));
      } catch (err) {
        captureMessage(
          'Could not write to IDB, maybe the customer is using incognito or does not allow Rasayel to write to their device',
          {
            contexts: {
              error: {
                message: (err as Error).message,
              },
            },
          },
        );
      }
    },
    {
      deep: true,
    },
  );

  wrapper.initialized = initialized;

  return wrapper;
}

export function useLocalStorageRef<T>(value: T, key: string) {
  let initialValue = value;
  try {
    initialValue = JSON.parse(localStorage.getItem(key) || 'null') || value;
  } catch {
    initialValue = value;
  }

  const proxy = ref<T>(initialValue);

  watch(
    proxy,
    newValue => {
      localStorage.setItem(key, JSON.stringify(newValue));
    },
    { deep: true },
  );

  return proxy;
}

export function debouncedRef<TValue>(value: TValue, delay: number) {
  return customRef<TValue>((track, trigger) => {
    let timeout: number;
    return {
      get() {
        track();

        return value;
      },
      set(newValue) {
        clearTimeout(timeout);

        timeout = window.setTimeout(() => {
          value = newValue;
          trigger();
        }, delay);
      },
    };
  });
}

let COMPUTED_INTERVAL: number;
const ONE_MINUTE = 60 * 1000;
const INTERVAL_COUNTER = ref(0);

export function useComputedWithMinuteTTL<TValue>(getter: () => TValue) {
  const prop = computed(() => {
    // invokes the computed getter
    // effectively adds the counter to the computed prop dependencies
    // allows us to invalidate the computed cache by changing the counter value
    // eslint-disable-next-line no-unused-expressions
    INTERVAL_COUNTER.value;

    // execute the actual getter and get the actual value
    return getter();
  });

  if (!COMPUTED_INTERVAL) {
    COMPUTED_INTERVAL = window.setInterval(() => {
      INTERVAL_COUNTER.value++;
    }, ONE_MINUTE);
  }

  return prop;
}

export function waitUntil(signal: Ref<boolean>, is: boolean, cb: () => void) {
  if (signal.value === is) {
    cb();
    return;
  }

  const unwatch = watch(signal, val => {
    if (val === is) {
      cb();
      unwatch?.();
    }
  });

  return unwatch;
}

export function whenever(signal: Ref<boolean>, cb: () => void) {
  const unwatch = watch(signal, val => {
    if (val) {
      cb();
    }
  });

  return unwatch;
}

export function useRefExists<T>(ref: Ref<T | null>) {
  return computed<boolean>({
    get() {
      return !!ref.value;
    },
    set(value) {
      if (!value) {
        ref.value = null;
      }
    },
  });
}

/**
 * Unwraps the LazyOrRef underlying value
 */
export function unwrapRefOrLazy<T>(value: LazyOrRefOrRaw<T>): T {
  const unwrapped = unref(value);

  return isCallable(unwrapped) ? unwrapped() : unwrapped;
}

export function useSessionRef<TValue>(key: string, def: TValue) {
  let initialValue = def;
  try {
    initialValue = JSON.parse(sessionStorage.getItem(key) || 'null') || def;
  } catch {
    initialValue = def;
  }

  const value = ref<TValue>(initialValue);
  watch(
    value,
    newVal => {
      sessionStorage.setItem(key, JSON.stringify(newVal));
    },
    { deep: true },
  );

  return value;
}

export function useAttrsWithout(attrNames: string | string[]) {
  const attrs = useAttrs();
  const attrsWithout = computed(() => {
    return omit(attrs, attrNames) as Record<string, any>;
  });

  return attrsWithout;
}

export function useAttrsOnly(attrNames: string | string[]) {
  const attrs = useAttrs();
  const attrsWithout = computed(() => {
    return pick(attrs, attrNames) as Record<string, any>;
  });

  return attrsWithout;
}

export function useSplitAttrs(attrNames: string | string[]) {
  const attrsWithout = useAttrsWithout(attrNames);
  const splitAttrs = useAttrsOnly(attrNames);

  return [attrsWithout, splitAttrs];
}

export function useAsyncPendingAction<
  TFunction extends (...args: any) => Promise<any>,
  TResult = ReturnType<TFunction>,
>(action: TFunction) {
  const isPending = ref(false);

  async function run(...args: Parameters<TFunction>): Promise<TResult> {
    isPending.value = true;
    const result = await action(...(args as any));
    isPending.value = false;

    return result;
  }

  return [run, isPending] as const;
}
