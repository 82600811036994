import { FeatureGloballyFlippedDocument } from '@shared/graphql/FeatureFlags';
import { useSubscription } from 'villus';
import { MaybeRefOrGetter, computed, toValue } from 'vue';
import { getAppData, useAppContext } from './app';

export function useFeatureFlagGloballyFlipped() {
  const { data } = useSubscription(
    {
      query: FeatureGloballyFlippedDocument,
    },
    next => {
      const payload = next.data?.featureFlipped;

      return payload;
    },
  );

  return data;
}

/** Does not use injections */
export function getFeature(name: string) {
  return getAppData()?.featureFlags.includes(name) || false;
}

export function useAppFeaturesLookup() {
  const { appFeatures: flags } = useAppContext();

  function hasFeature(name: string) {
    if (__IS_E2E_BUILD__) {
      return true;
    }

    if (name in flags.value) {
      return flags.value[name];
    }

    return false;
  }

  return {
    hasFeature,
  };
}

/**
 * Detects if a feature flag is enabled or not
 */
export function useIsAppFeatureEnabled(name: MaybeRefOrGetter<string>) {
  const { hasFeature } = useAppFeaturesLookup();

  return computed(() => {
    return hasFeature(toValue(name));
  });
}

/**
 * Detects if a feature flag is disabled or not
 */
export function useIsAppFeatureDisabled(name: MaybeRefOrGetter<string>) {
  const { hasFeature } = useAppFeaturesLookup();

  return computed(() => {
    return !hasFeature(toValue(name));
  });
}
