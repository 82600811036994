export function getPlatform() {
  if (!__IS_NATIVE_MOBILE__) {
    return getWebPlatform();
  }

  const platform = window.Capacitor?.getPlatform() || 'android';

  return platform === 'web' ? 'android' : platform;
}

function isIos() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

function isAndroid() {
  return /(android)/i.test(navigator.userAgent);
}

export function getWebPlatform() {
  if (isIos()) {
    return 'ios';
  }

  if (isAndroid()) {
    return 'android';
  }

  return navigator.platform.startsWith('Mac') ? 'Mac' : 'Windows';
}

export function addPlatformBodyClasses() {
  const platform = getPlatform();
  const classMap: Record<ReturnType<typeof getPlatform>, string> = {
    Mac: '',
    Windows: '',
    android: 'android',
    ios: 'ios',
  };

  const classToAdd = classMap[platform];
  if (classToAdd) {
    document.body.classList.add(classToAdd);
  }

  if (__IS_NATIVE_MOBILE__) {
    document.body.classList.add('app-native');
  }
}
