import { Directive } from 'vue';
import isEqual from 'fast-deep-equal';
import { getGlobalTooltipContext, TooltipProps } from '@web/features/tooltips';
import { Placement } from '@floating-ui/dom';

const EL_DATA_MAP = new Map<HTMLElement, TooltipProps>();

function onMouseEnter(this: HTMLElement, e: Event) {
  const ctx = getGlobalTooltipContext();
  const content = EL_DATA_MAP.get(this);
  if (!content?.label) {
    return;
  }

  ctx?.openAtEventTarget(e, content);
}

function onInputOrFocus(this: HTMLElement) {
  const ctx = getGlobalTooltipContext();
  if (ctx?.anchorRef.value === this) {
    ctx.close();
  }
}

interface TooltipModifiers {
  kbd?: boolean;
  right?: boolean;
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
}

function resolvePlacement(modifiers: TooltipModifiers): Placement {
  if (modifiers.right) {
    return 'right';
  }
  if (modifiers.top) {
    return 'top';
  }
  if (modifiers.bottom) {
    return 'bottom';
  }
  if (modifiers.left) {
    return 'left';
  }

  return 'bottom';
}

function buildTooltipProps(value: TooltipProps | string, modifiers: TooltipModifiers): TooltipProps {
  const val: Omit<TooltipProps, 'placement'> =
    typeof value === 'string' ? { label: value, type: 'TEXT_HINT' } : { ...value };
  val.type = modifiers.kbd ? 'KBD_SHORTCUT_HINT' : 'TEXT_HINT';

  return {
    ...val,
    placement: resolvePlacement(modifiers),
  } as TooltipProps;
}

/**
 * An alterative to v-html with auto escaping and markdown conversion features
 */
export const tooltipDirective: Directive<HTMLElement, TooltipProps | string> = {
  mounted(el, { value, modifiers }) {
    EL_DATA_MAP.set(el, buildTooltipProps(value, modifiers as unknown as TooltipModifiers));
    el.addEventListener('mouseenter', onMouseEnter);

    // if the element has editable content then we should close it
    if (el.contentEditable || el.tagName === 'INPUT') {
      el.addEventListener('input', onInputOrFocus);
      el.addEventListener('focus', onInputOrFocus);
    }
  },
  beforeUnmount(el) {
    el.removeEventListener('mouseenter', onMouseEnter);
    el.removeEventListener('focus', onInputOrFocus);
    el.removeEventListener('input', onInputOrFocus);
  },
  updated(el, { value, oldValue, modifiers }) {
    if (isEqual(value, oldValue)) {
      return;
    }

    EL_DATA_MAP.set(el, buildTooltipProps(value, modifiers as unknown as TooltipModifiers));
  },
};
