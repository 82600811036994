export async function loadScript(src: string, attrs?: Record<string, string>) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    if (attrs) {
      Object.keys(attrs).forEach(key => {
        script.setAttribute(key, attrs[key]);
      });
    }
    script.onload = () => resolve(true);
    script.onerror = reject;
    document.body.appendChild(script);
  });
}
