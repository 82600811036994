import { onBeforeUnmount, provide, Ref, ref } from 'vue';
import { KeyboardShortcutsKey } from '@web/injectionKeys';
import { Maybe, LazyOrRef, SharedKeyboardEvent } from '@shared/types';
import { toNonNullable } from '@shared/utils/collections';
import { getPlatform } from '@shared/utils/platform';
import { injectWithSelf } from '@shared/utils/common';
import { useIsMobileScreen } from '@shared/features/screen';
import { unwrapRefOrLazy } from '@shared/features/refs';
import { KeyPredicate, useEventListener } from '@vueuse/core';
import { isKeyEqual } from '@shared/utils/keyboard';

export type KeyboardKey =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z'
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '.'
  | ','
  | ';'
  | '['
  | ']'
  | '/'
  | '\\'
  | "'"
  | '`'
  | '§'
  | 'tab'
  | 'arrowUp'
  | 'arrowDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'pageUp'
  | 'pageDown'
  | 'home'
  | 'end'
  | 'space'
  | 'escape'
  | 'enter'
  | 'shift'
  | 'backspace'
  | 'return'
  | 'meta'
  | 'control';

// `cmd` is swapped for `ctrl` in non-mac environments
export type KeyModifier = 'cmd' | 'shift' | 'opt';

export interface KbdShortcutOptions {
  modifiers?: KeyModifier[];
  description: string;
  hiddenFromMenu?: boolean;
  disabled?: LazyOrRef<boolean>;
  availableDuringComposition?: boolean;
  hasPriority?: LazyOrRef<boolean>;
  handler(e: SharedKeyboardEvent): void;
}

interface KbdShortcutWithKey extends KbdShortcutOptions {
  id: string;
  key: KeyboardKey;
  combination: string[];
}

export interface KbdShortcutContext {
  shortcuts: Ref<KbdShortcutWithKey[]>;
}

export const KEY_SYMBOL_MAP: Partial<Record<KeyboardKey | KeyModifier, string>> = {
  escape: 'Esc',
  arrowUp: '↑',
  arrowDown: '↓',
  arrowLeft: '←',
  arrowRight: '→',
  return: '↵',
  cmd: getPlatform() === 'Mac' ? '⌘' : 'Ctrl',
  shift: '⇧',
  opt: getPlatform() === 'Mac' ? '⌥' : 'Alt',
};

function isComposableElement(el: HTMLElement): boolean {
  return ['INPUT', 'TEXTAREA'].includes(el.tagName) || el.isContentEditable;
}

export function useKeyboardShortcutContext() {
  let ctx = injectWithSelf(KeyboardShortcutsKey);
  if (ctx) {
    return ctx;
  }

  const isMobile = useIsMobileScreen();
  const shortcuts = ref<KbdShortcutWithKey[]>([]);
  function processShortcuts(e: SharedKeyboardEvent) {
    if (isMobile.value) {
      return;
    }

    const isMetaPressed = e.metaKey || e.ctrlKey;
    const matches = shortcuts.value.filter(shortcut => {
      if (!isKeyEqual(e.key || '', shortcut.key || '')) {
        return false;
      }

      const matchesMeta = !!shortcut.modifiers?.includes('cmd') === isMetaPressed;
      const matchesShift = !!shortcut.modifiers?.includes('shift') === e.shiftKey;
      const matchesOpt = !!shortcut.modifiers?.includes('opt') === e.altKey;

      return matchesMeta && matchesShift && matchesOpt && !unwrapRefOrLazy(shortcut.disabled);
    });

    let match = matches[0];
    if (matches.length > 1) {
      match = matches.find(p => !!unwrapRefOrLazy(p.hasPriority)) || matches[0];
    }

    // If no match, do nothing
    if (!match) {
      return;
    }

    // Don't trigger shortcuts for elements that are accepting input
    if (isComposableElement(e.target as HTMLElement) && !match.availableDuringComposition) {
      return;
    }

    match.handler(e);
  }

  useEventListener(window, 'keydown', processShortcuts);

  ctx = { shortcuts };
  provide(KeyboardShortcutsKey, ctx);

  return ctx;
}

let CMD_ID_COUNTER = 0;
function buildCmdId(combination: string[]) {
  return combination.join('_') + CMD_ID_COUNTER++;
}

export function defineKeyboardShortcut(key: KeyboardKey, opts: KbdShortcutOptions) {
  const { shortcuts } = useKeyboardShortcutContext();
  const combination = toNonNullable([
    opts.modifiers?.includes('cmd') ? KEY_SYMBOL_MAP.cmd : '',
    opts.modifiers?.includes('shift') ? KEY_SYMBOL_MAP.shift : '',
    opts.modifiers?.includes('opt') ? KEY_SYMBOL_MAP.opt : '',
    KEY_SYMBOL_MAP[key] || key,
  ]);

  const id = buildCmdId(combination);
  shortcuts.value.push({ id, combination, key, ...opts });
  onBeforeUnmount(() => {
    const shortcutIdx = shortcuts.value.findIndex(shortcut => {
      return shortcut.id === id;
    });

    if (shortcutIdx > -1) {
      shortcuts.value.splice(shortcutIdx, 1);
    }
  });
}

export function useIsHoldingKey(key: KeyboardKey | KeyboardKey[] | KeyPredicate) {
  const isHoldingKey = ref(false);

  function onKeydown(e: KeyboardEvent) {
    if (!e.key) {
      return;
    }

    if (typeof key === 'function') {
      if (key(e)) {
        isHoldingKey.value = true;
      }
      return;
    }

    if (isKeyEqual(e.key, key)) {
      isHoldingKey.value = true;
    }
  }

  function onKeyup(e: KeyboardEvent) {
    if (!e.key) {
      return;
    }

    if (typeof key === 'function') {
      if (key(e)) {
        isHoldingKey.value = false;
      }

      return;
    }

    if (isKeyEqual(e.key, key)) {
      isHoldingKey.value = false;
    }
  }

  if (!__IS_NATIVE_MOBILE__) {
    useEventListener(window, 'keydown', onKeydown);
    useEventListener(window, 'keyup', onKeyup);
  }

  return isHoldingKey;
}

interface KeyboardArrowsNavigationInit<TListItem>
  extends Pick<KbdShortcutOptions, 'disabled' | 'availableDuringComposition' | 'hasPriority'> {
  getItems: () => TListItem[];
  getCurrentItem: () => Maybe<TListItem>;
  onMove(evt: { nextItem: TListItem; prevItem?: TListItem; nextIdx: number; prevIdx: number }): void;
  compare?(lhs: TListItem, rhs: TListItem): boolean;
  itemsPerRow?: number;
}

type ArrowDirection = 'up' | 'down' | 'left' | 'right';

export function useArrowKeysNavigation<TListItem>(opts: KeyboardArrowsNavigationInit<TListItem>) {
  const itemsPerRow = opts?.itemsPerRow || 1;
  const compare = opts.compare || ((lhs, rhs) => lhs === rhs);

  // Maps grid movement in 4 arrow directions
  const incrementDirMap: Record<ArrowDirection, number> = {
    left: -1,
    right: 1,
    up: -itemsPerRow,
    down: itemsPerRow,
  };

  defineKeyboardShortcut('arrowUp', {
    description: 'Move up',
    availableDuringComposition: opts?.availableDuringComposition,
    disabled: opts.disabled,
    hasPriority: opts.hasPriority,
    handler(e) {
      e.preventDefault();
      onArrowPressed('up');
    },
  });

  defineKeyboardShortcut('arrowDown', {
    description: 'Move down',
    availableDuringComposition: opts?.availableDuringComposition,
    disabled: opts.disabled,
    hasPriority: opts.hasPriority,
    handler(e) {
      e.preventDefault();
      onArrowPressed('down');
    },
  });

  // Add grid movement to right/left
  if (itemsPerRow > 1) {
    defineKeyboardShortcut('arrowLeft', {
      description: 'Move left',
      availableDuringComposition: opts?.availableDuringComposition,
      disabled: opts.disabled,
      hasPriority: opts.hasPriority,
      handler(e) {
        e.preventDefault();
        onArrowPressed('left');
      },
    });

    defineKeyboardShortcut('arrowRight', {
      description: 'Move right',
      availableDuringComposition: opts?.availableDuringComposition,
      disabled: opts.disabled,
      hasPriority: opts.hasPriority,
      handler(e) {
        e.preventDefault();
        onArrowPressed('right');
      },
    });
  }

  function getCurrentPositionInfo() {
    const currentItem = opts.getCurrentItem();
    const items = opts.getItems();
    if (!currentItem) {
      return { idx: -1, currentItem: undefined, items };
    }

    const idx = items.findIndex(i => compare(i, currentItem));

    return {
      idx,
      currentItem,
      items,
    };
  }

  function moveTo(toIdx: number) {
    const { items, currentItem, idx } = getCurrentPositionInfo();
    if (!currentItem && !items[toIdx]) {
      if (items[0]) {
        opts.onMove({ nextItem: items[0], nextIdx: 0, prevIdx: 0 });
      }
      return;
    }

    let nextIdx = toIdx;
    // Makes sure we loop over both ends
    let nextItem = items[nextIdx];
    if (nextIdx < 0) {
      nextItem = items[items.length - 1];
      nextIdx = items.length - 1;
    }

    if (!nextItem) {
      if (items[0]) {
        opts.onMove({ nextItem: items[0], prevIdx: 0, nextIdx: 0, prevItem: currentItem });
      }

      return;
    }

    opts.onMove({ nextItem, prevItem: currentItem, prevIdx: idx, nextIdx });
  }

  function onArrowPressed(direction: ArrowDirection) {
    const { idx } = getCurrentPositionInfo();
    if (idx === -1) {
      moveTo(0);
      return;
    }

    moveTo(idx + incrementDirMap[direction]);
  }

  return {
    moveTo,
  };
}
