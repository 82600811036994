import { createAppEventDispatcher } from '@shared/features/appEvents';
import { ElementType, FailureNextStep, FailureType, ParsedFailureMetadata } from '@shared/types';
import { CombinedError } from 'villus';

const appErrorNames = [
  'NotFoundError',
  'UpdateRequiredError',
  'UnauthorizedError',
  'MaintenanceInProgress',
  'UnexpectedState',
] as const;

export type AppErrorName = ElementType<typeof appErrorNames>;

export type AppError = Error & { name: AppErrorName; code: number };

export type GqlErrorCode =
  | 'UNKNOWN_ERROR'
  | 'CHANNEL_DOES_NOT_EXIST'
  | 'CHANNEL_ALREADY_EXISTS'
  | 'CHANNEL_LIMIT_EXCEEDED'
  | 'UNSUPPORTED_CHANNEL'
  | 'AGENT_NAME_MISMATCH'
  | 'CHARACTER_LIMIT_EXCEEDED'
  | 'SENDING_ERROR'
  | 'USER_DOES_NOT_EXIST'
  | 'CHANNEL_USER_NOT_BLOCKED'
  | 'CHANNEL_USER_ALREADY_BLOCKED'
  | 'ATTRIBUTE_DOES_NOT_EXIST'
  | 'ATTRIBUTE_NOT_EDITABLE'
  | 'INVALID_DATA_ATTRIBUTE_TYPE'
  | 'ATTRIBUTE_COUNT_EXCEEDED'
  | 'ATTRIBUTE_ALREADY_EXISTS'
  | 'ATTRIBUTE_HAS_SPECIAL_CHAR'
  | 'TAG_DOES_NOT_EXIST'
  | 'APP_USER_ACCESS_DENIED'
  | 'APP_USER_ALREADY_INVITED'
  | 'APP_USER_ALREADY_EXISTS'
  | 'MENTION_NOT_FOUND'
  | 'INVALID_ENUM_VALUE'
  | 'MENTION_NOT_FOUND'
  | 'REPLY_ALREADY_EXISTS_ERROR'
  | 'MISSING_HUBSPOT_USER_INFO'
  | 'HUBSPOT_CONTACT_NOT_FOUND'
  | 'INTEGRATION_ALREADY_EXISTS'
  | 'INTEGRATION_IMPORT_ALREADY_EXISTS'
  | 'CREATE_CONTACT_LOCK_NOT_ACQUIRED'
  | 'CHANNEL_USER_ALREADY_LINKED'
  | 'CHANNEL_USER_NOT_LINKED'
  | 'USER_OR_IDENTIFIER_ALREADY_EXIST'
  | 'PHONE_INVALID'
  | 'EMAIL_INVALID'
  | 'IDENTIFIERS_REQUIRED'
  | 'IDENTIFIER_ALREADY_EXISTS'
  | 'CONVERSATION_WITH_USER_ALREADY_EXISTS'
  | 'REPLY_NOT_FOUND_ERROR'
  | 'CONVERSATION_WITH_IDENTIFIER_ALREADY_EXISTS'
  | 'FOLDER_ALREADY_EXISTS_ERROR'
  | 'FOLDER_NOT_FOUND_ERROR'
  | 'FOLDER_HAS_SAVED_REPLIES'
  | 'CUSTOMER_NOT_FOUND'
  | 'CHANNEL_USER_ALREADY_LINKED'
  | 'TOO_MANY_MATCHED_CUSTOMERS'
  | 'STORE_NOT_FOUND'
  | 'MISSING_LINE_ITEMS'
  | 'API_ERROR'
  | 'SUBSCRIPTION_NOT_FOUND'
  | 'SUBSCRIPTION_ALREADY_EXISTS'
  | 'BILLING_NOT_SUPPORTED_BY_PLATFORM'
  | 'MISSING_CUSTOMER_INFORMATION'
  | 'INVALID_PHONE_AND_MISSING_EMAIL'
  | 'MESSAGE_NOT_FOUND'
  | 'MESSAGE_NOT_RETRIABLE'
  | 'MESSAGE_TYPE_NOT_RETRIABLE'
  | 'MESSAGE_NOT_DELETABLE'
  | 'RULE_NOT_FOUND'
  | 'INVALID_CONDITION'
  | 'INVALID_ACTION'
  | 'CAMPAIGN_NOT_FOUND'
  | 'CAMPAIGN_NOT_EDITABLE'
  | 'INVALID_QUERY'
  | 'CAMPAIGN_NOT_DELETABLE'
  | 'TOO_MANY_CONTACTS'
  | 'MESSAGE_TEMPLATE_NOT_FOUND'
  | 'WORKFLOW_BOT_NOT_FOUND'
  | 'WORKFLOW_BOT_NOT_EDITABLE'
  | 'WORKFLOW_BOT_NOT_PUBLISHABLE'
  | 'WORKFLOW_BOT_WELCOME_OR_AWAY_MESSAGE_EXISTS'
  | 'WORKFLOW_BOT_NOT_UNIQUE'
  | 'WORKFLOW_BOT_ALREADY_INSTALLED'
  | 'WORKFLOW_BOT_NODE_NOT_FOUND'
  | 'WORKFLOW_BOT_NODE_NOT_DELETABLE'
  | 'WORKFLOW_BOT_NODE_SOURCE_NOT_FOUND'
  | 'WORKFLOW_BOT_NODE_NOT_UNIQUE'
  | 'INVALID_REPLY'
  | 'INVALID_MESSAGE_HEADER'
  | 'INVALID_MESSAGE'
  | 'INVALID_MESSAGE_FOOTER'
  | 'WORKFLOW_BOT_NODE_REPLY_IDS_NOT_UNIQUE'
  | 'WORKFLOW_BOT_NODE_TOO_MANY_REPLIES'
  | 'UNAUTHORIZED'
  | 'ROLE_NOT_FOUND'
  | 'ROLE_NOT_EDITABLE'
  | 'ROLE_NAME_RESERVED'
  | 'ROLE_NOT_UNIQUE'
  | 'SCHEDULED_MAINTENANCE_ERROR'
  | 'FILE_TOO_LARGE'
  | 'DUPLICATED_IDENTIFIER'
  | 'PHONE_OR_EMAIL_HEADERS_MISSING'
  | 'SUBSCRIPTION_ALREADY_EXISTS'
  | 'SAMPLE_TEMPLATE_NOT_DELETABLE'
  | 'MESSAGE_TEMPLATE_IN_USE'
  | 'INBOX_VIEW_FOLDER_NOT_DELETABLE'
  | 'AI_DATA_SOURCE_PROCESSING'
  | 'ACTIVATION_ERROR'
  | 'PIPEDRIVE_PERSON_NOT_FOUND'
  | 'SUBSCRIPTION_EXPIRED'
  | 'SEQUENCE_NOT_UNIQUE'
  | 'SEQUENCE_NOT_FOUND'
  | 'SEQUENCE_NOT_EDITABLE'
  | 'SEQUENCE_ALREADY_PUBLISHED'
  | 'SEQUENCE_ALREADY_PAUSED'
  | 'SEQUENCE_NOT_PUBLISHED'
  | 'MISSING_PIPEDRIVE_USER_INFO'
  | 'ENTITY_ALREADY_EXISTS'
  | 'MISSING_CHANNEL_USER_INFO'
  | 'INVALID_DOMAIN_PROTOCOL'
  | 'DATABASE_NOT_FOUND'
  | 'TRIAL_PROACTIVE_MESSAGE_LIMIT_REACHED'
  | 'CUSTOM_OWNERSHIP_ERROR'
  | 'CUSTOM_OWNERSHIP_NOT_MATCHED'
  | 'SALESFORCE_API_LIMIT_EXCEEDED'
  | 'RASAYEL_SALESFORCE_API_LIMIT_EXCEEDED'
  | 'SALESFORCE_DUPLICATES_DETECTED';

interface AppErrorOptions {
  name: AppErrorName;
  message: string;
  code: number;
}

/**
 * Creates an app error instance
 */
export function createAppError({ name, message, code }: AppErrorOptions): AppError {
  const error: Error = new Error(message);
  error.name = name;
  (error as AppError).code = code;

  return error as AppError;
}

/**
 * Ensures an error is of type AppError
 */
export function isAppError(err: unknown): err is AppError {
  return appErrorNames.includes((err as any) && (err as Error).name);
}

/**
 * Checks if an API error is of a specific type
 */
export function hasErrorCode(err: unknown, code: GqlErrorCode | GqlErrorCode[]): boolean {
  if (!(err instanceof CombinedError) || !err.isGraphQLError) {
    return false;
  }

  const errorCode: GqlErrorCode | undefined = getErrorCode(err);
  if (!errorCode) {
    return false;
  }

  if (Array.isArray(code)) {
    return code.includes(errorCode);
  }

  return errorCode === code;
}

export function getErrorCode(err: unknown): GqlErrorCode | undefined {
  if (!(err instanceof CombinedError) || !err.isGraphQLError) {
    return undefined;
  }

  return (err.graphqlErrors?.[0]?.extensions?.code || 'UNKNOWN_ERROR') as GqlErrorCode;
}

/**
 * Checks if an error is unknown and thus cannot be handled or recovered from by FE
 */
export function isUnknownError(err: unknown): boolean {
  if (!(err instanceof CombinedError) || !err.isGraphQLError) {
    return true;
  }

  // if its a complete failure with non-200 status code and no graphql response
  if (!err.isGraphQLError) {
    return !err.message.includes('[Network]');
  }

  const code = err.graphqlErrors?.[0].extensions?.code;

  // if no extensions are present then we have no idea what that error is
  // or if the error has unknown code explicitly
  return code === undefined || code === null || code === 'UNKNOWN_ERROR';
}

/**
 * Checks if the performed operation can be retried
 */
export function canRetryOperation(err: CombinedError): boolean {
  if (!err.isGraphQLError) {
    return false;
  }

  return (err.graphqlErrors?.[0].extensions?.retriable as boolean) ?? false;
}

/**
 * Exposes a useful errors information based on the extensions if available
 */
export function parseFailureMetadata(err: unknown): ParsedFailureMetadata {
  if (!err || !(err instanceof CombinedError)) {
    const message = err ? (err as Error).message : undefined;
    return {
      code: 'UNKNOWN_ERROR',
      reason: message || 'Unknown error has occurred',
      retriable: false,
      failureType: 'UnknownError',
      internalErrorCode: 'UnknownError',
      nextStep: {
        action: 'ContactSupport',
      },
    };
  }

  if (!err.isGraphQLError) {
    return {
      code: 'UNKNOWN_ERROR',
      reason: err.message,
      retriable: false,
      failureType: 'UnknownError',
      originalError: err.response.body.errors,
      failureGuidance: 'Copy the error and reach out to support for further assistance.',
      internalErrorCode: 'UnknownError',
      nextStep: {
        action: 'ContactSupport',
      },
    };
  }

  const extensions = err.graphqlErrors?.[0].extensions;
  if (!extensions) {
    return {
      code: 'UNKNOWN_ERROR',
      reason: err.message,
      retriable: false,
      failureType: 'UnknownError',
      internalErrorCode: 'UnknownError',
      nextStep: {
        action: 'ContactSupport',
      },
    };
  }

  return {
    code: extensions.code as GqlErrorCode,
    reason: extensions.reason as string,
    referenceUrl: extensions.referenceUrl as string,
    retriable: extensions.retriable as boolean,
    retriableAfter: extensions.retriableAfter as number,
    failureType: extensions.failureType as FailureType,
    failureContext: extensions.failureContext as string,
    failureGuidance: extensions.failureGuidance as string,
    originalError: extensions.originalError,
    internalErrorCode: extensions.internalErrorCode as string,
    nextStep: extensions.nextSteps as FailureNextStep,
  };
}

export const [dispatchErrorHandler, onAppError] = createAppEventDispatcher<{ error: AppError }>('APP_ERROR');
