import { Placement } from '@floating-ui/dom';
import { useFloatingContainer } from './floatingMenus';
import { KeyboardKey, KeyModifier } from './shortcuts';

export interface BaseTooltip {
  label: string;
  placement: Placement;
}

export interface BasicTooltip extends BaseTooltip {
  type: 'TEXT_HINT';
}

export interface KeyboardShortcutTooltip extends BaseTooltip {
  type: 'KBD_SHORTCUT_HINT';
  keys: (KeyboardKey | KeyModifier)[];
}

export type TooltipProps = BasicTooltip | KeyboardShortcutTooltip;

export type GlobalTooltipContext = ReturnType<typeof createTooltipContext>;

export const TOOLTIP_GROUP_NAME = 'globalTooltip';

export let GLOBAL_CTX: GlobalTooltipContext | null = null;

export function useGlobalTooltipContext() {
  if (!GLOBAL_CTX) {
    GLOBAL_CTX = createTooltipContext();
  }

  return GLOBAL_CTX;
}

function createTooltipContext() {
  const context = useFloatingContainer<TooltipProps>(TOOLTIP_GROUP_NAME);

  return context;
}

export function getGlobalTooltipContext() {
  return GLOBAL_CTX;
}
