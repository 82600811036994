import { captureException } from '@sentry/browser';
import { DebouncedFunc, memoize, get as getFromPath } from 'lodash-es';
import { computed, getCurrentInstance, inject, InjectionKey, MaybeRefOrGetter, Ref, toValue } from 'vue';
import { isIndex, isNullOrUndefined, isObject } from './assertions';

export function injectStrict<T>(key: InjectionKey<T>) {
  const injection = inject(key);
  if (!injection) {
    const error = new Error(`Could not resolve injection for ${key.toString()}`);
    captureException(error);

    throw error;
  }

  return injection;
}

// Uses same component provide as its own injections
// Due to changes in https://github.com/vuejs/vue-next/pull/2424
export function injectWithSelf<T>(symbol: InjectionKey<T>, def: T | (() => T)): T;
export function injectWithSelf<T>(symbol: InjectionKey<T>): T | undefined;
export function injectWithSelf<T>(symbol: InjectionKey<T>, def?: T | (() => T)): T | undefined {
  const vm = getCurrentInstance() as any;
  const provided = vm?.provides[symbol as any];
  if (provided) {
    return provided;
  }

  if (typeof def === 'function') {
    return inject(symbol, def, true);
  }

  return inject(symbol, def);
}

export function injectWithSelfStrict<T>(key: InjectionKey<T>) {
  const injection = injectWithSelf(key);
  if (!injection) {
    const error = new Error(`Could not resolve injection for ${key.toString()}`);
    captureException(error);

    throw error;
  }

  return injection;
}

export function normalizeConversationId(id: unknown): number | undefined {
  if (id === 'new') {
    return undefined;
  }

  const numericId = Number(id);

  return numericId || undefined;
}

function requestIdleCallbackShim(cb: (...args: any[]) => any) {
  setTimeout(cb, 1);
}

export const requestIdleCallback = window.requestIdleCallback || requestIdleCallbackShim;

export function debounceAsync<TFunction extends (...args: any) => Promise<any>, TResult = ReturnType<TFunction>>(
  inner: TFunction,
  ms = 0,
): (...args: Parameters<TFunction>) => Promise<TResult> {
  let timer: number | null = null;
  let resolves: any[] = [];

  return function (...args: Parameters<TFunction>) {
    // Run the function after a certain amount of time
    if (timer) {
      window.clearTimeout(timer);
    }

    timer = window.setTimeout(() => {
      // Get the result of the inner function, then apply it to the resolve function of
      // each promise that has been created since the last time the inner function was run
      const result = inner(...(args as any));

      resolves.forEach(r => r(result));
      resolves = [];
    }, ms);

    return new Promise<TResult>(resolve => resolves.push(resolve));
  };
}

/**
 * Very cool concept when you want to debounce a fn but you are using it for different inputs
 * This will ensure each unique parameter call has its own debounce, meaning they do not share it.
 */
export function memoizedDebounce<TFunction extends (...args: any[]) => any>(
  fn: TFunction,
  idResolver: (...args: Parameters<TFunction>) => string,
  wait = 0,
): (...args: Parameters<TFunction>) => ReturnType<TFunction> | undefined {
  const debouncedMemo = memoize<(...args: Parameters<TFunction>) => DebouncedFunc<TFunction>>(
    (...args: Parameters<TFunction>) => debounceAsync(fn, wait) as any,
    idResolver,
  );

  function wrappedFunction(...args: Parameters<TFunction>): ReturnType<TFunction> | undefined {
    return debouncedMemo(...args)(...args);
  }

  return wrappedFunction;
}

export function useAnyOf(args: MaybeRefOrGetter<boolean>[]) {
  return computed(() => args.some(a => toValue(a)));
}

export function sampledFlag(flag: Ref<boolean>, percentage: number) {
  const threshold = percentage > 1 ? 1 : percentage;
  const randomNumber = Math.random();

  return randomNumber < threshold && flag.value;
}

export function unset(object: Record<string, unknown> | unknown[], key: string | number) {
  if (Array.isArray(object) && isIndex(key)) {
    object.splice(Number(key), 1);
    return;
  }

  if (isObject(object)) {
    delete object[key];
  }
}

/**
 * Removes a nested property from object
 */
export function unsetPath(object: Record<string, unknown>, path: string[]): void {
  const keys = [...path];
  let acc: Record<string, unknown> = object;
  for (let i = 0; i < keys.length; i++) {
    // Last key, unset it
    if (i === keys.length - 1) {
      unset(acc, keys[i]);
      break;
    }

    // Key does not exist, exit
    if (!(keys[i] in acc) || isNullOrUndefined(acc[keys[i]])) {
      break;
    }

    acc = acc[keys[i]] as Record<string, unknown>;
  }
}

export function toArray<TItem>(itemOrArray: TItem | TItem[]): TItem[] {
  return Array.isArray(itemOrArray) ? itemOrArray : [itemOrArray];
}

export function unArray<TItem>(array: TItem | TItem[]): TItem {
  return Array.isArray(array) ? array[0] : array;
}

export function getFlagEmoji(countryCode: string) {
  return countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(127397 + char.charCodeAt(0)));
}
