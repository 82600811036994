<template>
  <div class="py-16 h-full flex flex-col items-center justify-center bg-dots">
    <div class="flex-shrink-0 flex justify-center">
      <router-link to="/" class="inline-flex">
        <span class="sr-only">Rasayel</span>
        <img class="w-8 h-auto" src="@shared/assets/img/logo-shape.svg" alt="" />
      </router-link>
    </div>

    <h1 class="mt-6 text-3xl leading-9 font-medium text-gray-800">Page not found.</h1>
    <p class="mt-1 text-sm leading-5 font-normal text-gray-600">Sorry, we couldn't find the page you're looking for.</p>

    <BaseButton class="mt-11" as="router-link" to="/">
      <PhHouseFill />
      Home
    </BaseButton>
  </div>
</template>

<style scoped lang="postcss">
.bg-dots {
  background-color: #ffffff;
  background-image: url('@shared/assets/img/dots-pattern.svg');
  @apply bg-repeat;
  background-size: 200px 200px;
}
</style>
