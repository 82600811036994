import { CombinedError, useQuery } from 'villus';
import { keyBy } from 'lodash-es';
import { until } from '@vueuse/core';
import { computed, InjectionKey, onMounted, provide, Ref, watch } from 'vue';
import { setContext as setSentryContext, setTags as setSentryTags } from '@sentry/browser';
import { useStoredRef } from '@shared/features/refs';
import { AppBotUsersDocument, AppBotUsersQuery, AppInfoDocument } from '@shared/graphql/App';
import { AppData, Unpacked } from '@shared/types';
import { injectWithSelf } from '@shared/utils/common';
import { valuesOf } from '@shared/utils/collections';
import { createAppError } from '@shared/utils/errors';
import { onAppSubscriptionChanged, onChannelConnected } from '@shared/features/appEvents';

export const AppContextInjectionKey: InjectionKey<AppContext> = Symbol('Current App');

/**
 * Holds live ref to the app data
 */
const appData = useStoredRef<AppData | undefined>(undefined, 'app');

export const getAppData = () => appData.value;

export interface AppContext {
  app: Ref<AppData | undefined>;
  refresh: () => Promise<AppData | undefined>;
  isFetching: Ref<boolean>;
  isSyncing: Ref<boolean>;
  appFeatures: Ref<Record<string, boolean>>;
  error: Ref<CombinedError | null>;
  setAppData: (data: Partial<AppData>) => void;
}

export function useAppContext() {
  const globalCtx = injectWithSelf(AppContextInjectionKey, null);
  if (globalCtx) {
    return globalCtx;
  }

  // const mixpanel = useMixpanel();
  const { execute, data, error, isFetching } = useQuery({
    query: AppInfoDocument,
  });

  onAppSubscriptionChanged(() => execute());
  onChannelConnected(() => execute());

  watch(data, newValue => {
    appData.value = newValue?.app;
    if (appData.value) {
      setSentryContext('app', {
        appId: appData.value.id,
        accountId: appData.value.account.id,
      });

      setSentryTags({
        app_id: appData.value.id,
        account_id: appData.value.account.id,
      });

      // mixpanel.setGlobalContext({
      //   app_id: appData.value.id,
      //   account_id: appData.value.account.id,
      //   app_name: appData.value.displayName || '',
      //   subscription: appData.value.account.subscription.tier || 'Trial',
      // });
    }
  });

  const appFeatures = computed(() => {
    return (appData.value?.featureFlags || []).reduce(
      (features, featureKey) => {
        features[featureKey] = true;

        return features;
      },
      {} as Record<string, boolean>,
    );
  });

  const appCtx: AppContext = {
    app: appData,
    refresh: () => execute().then(r => r.data?.app),
    appFeatures,
    isFetching: computed(() => !appData.value && isFetching.value),
    isSyncing: isFetching,
    error,
    setAppData,
  };

  function setAppData(newData: Partial<AppData>) {
    appData.value = Object.assign(appData.value || ({} as AppData), newData);
  }

  provide(AppContextInjectionKey, appCtx);

  return appCtx;
}

export function useHandleAppInMaintenance() {
  const app = useAppContext();
  function throwMaintenanceError() {
    throw createAppError({
      name: 'MaintenanceInProgress',
      message: '',
      code: 503,
    });
  }

  async function checkForMaintenance() {
    // Wait till fresh state is fully fetched and not a stale state
    if (app.isSyncing.value) {
      await until(app.isFetching).toBe(false);
    }

    const data = await app.refresh();

    if (data?.featureFlags.includes('scheduled_maintenance')) {
      throwMaintenanceError();
    }
  }

  onMounted(checkForMaintenance);
}

export const AppBotsContextKey: InjectionKey<AppBotsContext> = Symbol('App bots context');

export type BotUserNode = Unpacked<AppBotUsersQuery['app']['botUsers']['nodes']>;

export interface AppBotsContext {
  botUsers: Ref<BotUserNode[]>;
  findBotUser(id: number): BotUserNode | undefined;
  isFetching: Ref<boolean>;
}

/**
 * Holds a ref to the bot users collection during the lifecycle of the app.
 */
const botUsersById = useStoredRef<Record<string | number, BotUserNode>>({}, 'botUsers');

export function useAppBotUsersContext() {
  const previous = injectWithSelf(AppBotsContextKey, null);
  if (previous) {
    return previous;
  }

  const { data, isFetching } = useQuery({
    query: AppBotUsersDocument,
  });

  watch(data, value => {
    botUsersById.value = keyBy(value?.app.botUsers.nodes, 'id');
  });

  // Expose a read-only version of it
  const botUsers = computed(() => {
    return valuesOf(botUsersById.value);
  });

  function findBotUser(botId: number): BotUserNode | undefined {
    return botUsersById.value[botId];
  }

  const ctx: AppBotsContext = {
    botUsers,
    findBotUser,
    isFetching,
  };

  provide(AppBotsContextKey, ctx);

  return ctx;
}
