import * as Types from '../types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type FeatureGloballyFlippedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type FeatureGloballyFlippedSubscription = { __typename?: 'Subscription', featureFlipped: { __typename?: 'FeatureFlippedPayload', feature: string, enabled: boolean } };

export declare const FeatureGloballyFlipped: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const FeatureGloballyFlippedDocument = new TypedDocumentString(`
    subscription FeatureGloballyFlipped {
  featureFlipped {
    feature
    enabled
  }
}
    `) as unknown as TypedDocumentString<FeatureGloballyFlippedSubscription, FeatureGloballyFlippedSubscriptionVariables>;