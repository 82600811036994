import { computed, ref } from 'vue';
import { differenceInDays } from 'date-fns';
import { useSubscription } from 'villus';
import { AccountUpdatedDocument } from '@web/graphql/App';
import { useAppContext } from '@shared/features/app';

export function useSubscriptionDetails() {
  const { app } = useAppContext();
  const subscription = ref(app.value?.account.subscription);

  useSubscription(
    {
      query: AccountUpdatedDocument,
    },
    next => {
      const payload = next.data?.accountUpdated;
      if (!payload) {
        return;
      }

      subscription.value = payload.subscription;
    },
  );

  return computed(() => {
    return {
      locked: subscription.value?.locked,
      status: subscription.value?.status,
      isLegacy: subscription.value?.isLegacy,
      trialExpiresAt: subscription.value?.trialExpiresAt ? subscription.value.trialExpiresAt * 1000 : null,
      canceledAt: subscription.value?.canceledAt ? subscription.value.canceledAt * 1000 : null,
      willBeCanceledAt: subscription.value?.willBeCanceledAt ? subscription.value.willBeCanceledAt * 1000 : null,
      platformType: subscription.value?.platformType,
      tier: subscription.value?.tier,
      paymentMode: subscription.value?.paymentMode,
    };
  });
}

/**
 * Indicates if the account is locked for payment reasons or is explicitly disabled
 */
export function useIsSubscriptionActive() {
  const subscription = useSubscriptionDetails();

  return computed(
    () =>
      subscription.value.status !== 'CANCELED' &&
      subscription.value.status !== 'EXPIRED' &&
      !(subscription.value.locked ?? false),
  );
}

/**
 * Returns an enum to indicate if there is a problem with billing, expands the API status
 */
export function useBillingStatus() {
  const subscription = useSubscriptionDetails();

  return computed<'LOCKED' | 'UNPAID' | 'TRIAL_EXPIRING' | 'TRIAL_EXPIRED' | 'ACTIVE' | 'CANCELED' | 'PENDING_CANCEL'>(
    () => {
      if (subscription.value.locked) {
        return 'LOCKED';
      }

      const status = subscription.value.status;
      if (status === 'UNPAID') {
        return 'UNPAID';
      }

      if (
        status === 'TRIAL' &&
        subscription.value.trialExpiresAt &&
        differenceInDays(subscription.value.trialExpiresAt, new Date()) <= 3
      ) {
        return 'TRIAL_EXPIRING';
      }

      if (status === 'EXPIRED') {
        return 'TRIAL_EXPIRED';
      }

      if (status === 'CANCELED') {
        return 'CANCELED';
      }

      if (status === 'ACTIVE' && subscription.value.willBeCanceledAt) {
        return 'PENDING_CANCEL';
      }

      return 'ACTIVE';
    },
  );
}

/**
 * Singleton state for the billing dialog
 */
const isShown = ref(false);
const dialogMessage = ref('');

/**
 * Controls a global modal showing billing dialog
 */
export function useBillingDialog() {
  const isActive = useIsSubscriptionActive();

  function show(message?: string) {
    dialogMessage.value = message || 'To continue you must update your billing settings';
    isShown.value = true;
  }

  function close() {
    dialogMessage.value = '';
    isShown.value = false;
  }

  function withBillingCheck<TReturn = unknown>(cb: () => TReturn, message?: string) {
    if (isActive.value) {
      return cb();
    }

    show(message);
  }

  return {
    withBillingCheck,
    message: dialogMessage,
    isShown,
    show,
    close,
  };
}
