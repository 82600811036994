import createEmojiRegex from 'emoji-regex';
import { escapeRegExp } from 'lodash-es';
import { ConditionOperatorInputKey, ConversationListItem, Maybe, MaybeArray, StreamMessage } from '@shared/types';
import { ChannelTypeEnum, MessageSubTypeEnum, MessageTypeEnum } from '@shared/types/graphql.gen';
import { MessageNodeFragment } from '@shared/graphql/fragments';

export function isNullOrUndefined(value: unknown): value is undefined | null {
  return value === null || value === undefined;
}

export function isEmpty(value: unknown): value is undefined | null | '' {
  return value === null || value === undefined || value === '';
}

export function isTextualElement(element: Maybe<HTMLElement | Element>) {
  if (!element) {
    return false;
  }

  return (
    (element as HTMLElement).contentEditable === 'true' || element.tagName === 'INPUT' || element.tagName === 'TEXTAREA'
  );
}

/**
 * Asserts that a fragment node is a media message.
 * All that type stuff is needed to force TS to overlap the fragment union with the text node discriminators
 * Which allows it to infer the node type correctly with all it's properties
 */
export function isMediaMessageNode(node: Pick<MessageNodeFragment, 'messageType'>): node is MessageNodeFragment & {
  messageType: 'IMAGE' | 'VIDEO' | 'AUDIO' | 'DOCUMENT';
  __typename: 'ImageMessage' | 'VideoMessage' | 'AudioMessage' | 'DocumentMessage';
} {
  const types = ['IMAGE', 'VIDEO', 'AUDIO', 'DOCUMENT'];

  return types.includes(node.messageType);
}

/**
 * Asserts that a message stream item main content is text only
 */
export function isOnlyTextMessageNode<TNode extends { type: StreamMessage['type'] }>(
  node: TNode,
): node is TNode & { body: string } {
  return (
    ['TEXT', 'TEMPLATE', 'NOTE'] as (MessageTypeEnum | 'NOTE' | 'DELETED' | 'QUICK_REPLY' | 'AI_SUMMARY')[]
  ).includes(node.type);
}

/**
 * Asserts that a channel type is a waba channel
 */
export function isWabaChannel(
  type: unknown,
): type is 'DIALOG_WABA' | 'TWILIO_WABA' | 'MESSAGEBIRD_WABA' | 'CM_WABA' | 'TELNYX_WABA' | 'CLOUD_WABA' {
  const wabaTypes: ChannelTypeEnum[] = [
    'DIALOG_WABA',
    'TWILIO_WABA',
    'MESSAGEBIRD_WABA',
    'CM_WABA',
    'TELNYX_WABA',
    'CLOUD_WABA',
  ];

  return wabaTypes.includes(type as ChannelTypeEnum);
}

/**
 * Asserts that a channel type is a facebook channel
 */
export function isFacebookChannel(type: unknown): type is 'FACEBOOK' {
  return type === 'FACEBOOK';
}
/**
 * Asserts that a channel type is an Instagram channel
 */
export function isInstagramChannel(type: unknown): type is 'INSTAGRAM' {
  return type === 'INSTAGRAM';
}
/**
 * Asserts that a channel type is an Email channel
 */
export function isEmailChannel(type: unknown): type is 'EMAIL' {
  return type === 'EMAIL';
}

/**
 * Asserts that a channel type is a Whatsapp Cloud API channel
 */
export function isCloudWabaChannel(type: unknown): type is 'CLOUD_WABA' {
  return type === 'CLOUD_WABA';
}

export function isTaggableChannel(type: unknown): type is 'FACEBOOK' | 'INSTAGRAM' {
  return isFacebookChannel(type) || isInstagramChannel(type);
}

export function isSmsChannel(type: unknown): type is 'TWILIO_SMS' {
  const smsTypes: ChannelTypeEnum[] = ['TWILIO_SMS'];

  return smsTypes.includes(type as ChannelTypeEnum);
}

/**
 * Asserts that the message node is of an internal type
 */
export function isInternalEventMessageNode(
  node: Pick<MessageNodeFragment, 'messageType' | 'messageSubtype'>,
): node is MessageNodeFragment & {
  __typename: 'StateActionMessage' | 'AssignmentActionMessage';
} {
  return (
    node.messageType === 'INTERNAL' &&
    (
      [
        'SYSTEM_OPEN',
        'SYSTEM_WAITING',
        'SYSTEM_BLOCK',
        'SYSTEM_CLOSE',
        'SYSTEM_TERMINATE',
        'SYSTEM_CAMPAIGN_WORKFLOW_BOT_CONTROLLED',
        'SYSTEM_PROACTIVE_WORKFLOW_BOT_CONTROLLED',
        'SYSTEM_WORKFLOW_BOT_CONTROLLED',
        'SYSTEM_SEQUENCE_CONTROLLED',
        'SYSTEM_ASSIGNMENT',
        'SYSTEM_SNOOZE',
        'SYSTEM_UNSNOOZE',
      ] as (MessageSubTypeEnum | undefined | null)[]
    ).includes(node.messageSubtype)
  );
}

/**
 * Checks if the given string is a valid phone number
 */
export function isPhoneNumber(value?: string) {
  if (!value) {
    return false;
  }

  return /^\+?(?:[0-9] ?){6,14}[0-9]$/.test(value);
}

// This regex matches every string with any emoji in it, not just strings that only have emojis
const originalEmojiRegex = createEmojiRegex();

// Make sure we match strings that _only_ contain emojis (and whitespace)
const regex = new RegExp('^(' + originalEmojiRegex.toString().replace(/\/g$/, '') + '|\\s)+$');

/**
 * https://github.com/withspectrum/spectrum/blob/alpha/shared/only-contains-emoji.js
 */
export function onlyContainsEmoji(text: string) {
  return regex.test(text);
}

export function hasEmoji(text: string) {
  return originalEmojiRegex.test(text);
}

export function isExistingConversation(
  conversation: Maybe<ConversationListItem>,
): conversation is ConversationListItem & { kind: 'EXISTING' } {
  return !!conversation?.id;
}

declare type AnyFunction = (...args: unknown[]) => unknown;

/**
 * Checks if the value is a function and can be called
 * Helps us avoid this issue
 * https://github.com/microsoft/TypeScript/issues/37663
 */
export function isCallable<T extends AnyFunction>(value: unknown): value is T {
  return typeof value === 'function';
}

export function isShopifyDomain(value: string) {
  return /(\.myshopify\.com)$/.test(value);
}

export function isDataAttributeFilter(
  value: unknown,
): value is
  | `DATA_ATTRIBUTE_BOOL_${number}`
  | `DATA_ATTRIBUTE_TEXT_${number}`
  | `DATA_ATTRIBUTE_SELECT_${number}`
  | `DATA_ATTRIBUTE_NUMBER_${number}`
  | `DATA_ATTRIBUTE_DECIMAL_${number}`
  | `DATA_ATTRIBUTE_DATE_${number}` {
  return String(value).startsWith('DATA_ATTRIBUTE_');
}

export function isValueMatchedByFilter(
  operator: ConditionOperatorInputKey,
  actual: any,
  expected: MaybeArray<string | number> | null | undefined,
) {
  if (operator === 'in' || operator === 'notIn') {
    const expectedArr = Array.isArray(expected) ? expected : [expected];
    const included = Array.isArray(actual) ? !!actual.find(v => expectedArr.includes(v)) : expectedArr.includes(actual);

    return operator === 'in' ? included : !included;
  }

  if (operator === 'before' || operator === 'after') {
    const expectedNum = Number(Array.isArray(expected) ? expected[0] : expected) * 1000;

    return operator === 'before' ? actual <= expectedNum : actual >= expectedNum;
  }

  // Operators afterwards only work on strings
  const expectedStr = Array.isArray(expected) ? String(expected[0]) : String(expected);
  const actualStr = Array.isArray(actual) ? actual.map(String) : String(actual);

  if (operator === 'contains' || operator === 'notContains') {
    const contained = actualStr.includes(expectedStr);

    return operator === 'contains' ? contained : !contained;
  }

  if (operator === 'startsWith') {
    return Array.isArray(actualStr)
      ? actualStr.some(str => str.startsWith(expectedStr))
      : actualStr.startsWith(expectedStr);
  }

  if (operator === 'endsWith') {
    return Array.isArray(actualStr)
      ? actualStr.some(str => str.endsWith(expectedStr))
      : actualStr.endsWith(expectedStr);
  }

  if (operator === 'match') {
    const reg = new RegExp(escapeRegExp(expectedStr), 'i');

    return Array.isArray(actualStr) ? actualStr.some(str => reg.test(str)) : reg.test(actualStr);
  }

  if (operator === 'isNot') {
    return Array.isArray(actualStr) ? actualStr.every(str => str !== expectedStr) : actualStr !== expectedStr;
  }

  // is operator
  return Array.isArray(actualStr) ? actualStr.some(str => str === expectedStr) : actualStr === expectedStr;
}

export function isIndex(value: unknown): value is number {
  return Number(value) >= 0;
}

export function isObject(obj: unknown): obj is Record<string, unknown> {
  return obj !== null && !!obj && typeof obj === 'object' && !Array.isArray(obj);
}

export function isFlowVariableFilter(
  value: unknown,
): value is
  | `FLOW_VARIABLE_BOOL_${string}`
  | `FLOW_VARIABLE_TEXT_${string}`
  | `FLOW_VARIABLE_NUMBER_${string}`
  | `FLOW_VARIABLE_DECIMAL_${string}`
  | `FLOW_VARIABLE_DATE_${string}` {
  return String(value).startsWith('FLOW_VARIABLE_');
}

export function isSystemVariableFilter(value: unknown): value is `SYSTEM_VARIABLE_${string}` {
  return String(value).startsWith('SYSTEM_VARIABLE_');
}

export function isIntegrationVariableFilter(value: unknown): value is `INTEGRATION_VARIABLE_${string}` {
  return String(value).startsWith('INTEGRATION_VARIABLE_');
}
