import { Directive } from 'vue';
import { marked } from 'marked';
import linkifyHtml from 'linkify-html';
import { escape } from 'lodash-es';
import { markdownToEscapedHTML } from '@shared/utils/string';

const linkifyOptions = () => ({
  defaultProtocol: 'https',
});

/**
 * An alterative to v-html with auto escaping and markdown conversion features
 */
export const contentDirective: Directive<HTMLElement, string | undefined> = (el, { value, modifiers, arg }) => {
  if (!value) {
    return;
  }

  // If the text modifier is enabled, it cleans all HTML tags. This will cause the directive to render raw clean text.
  if (modifiers.text) {
    el.innerHTML = markdownToEscapedHTML(value);
    return;
  }

  if (arg === 'markdown' || modifiers.markdown) {
    el.innerHTML = processMarkdown(value);
    return;
  }

  el.innerHTML = processHTML(value);
};

const MD_REPLACEMENTS = [[/^\\>/gm, '>']] as const;

// escape HTML elements and possible XSS attempts
export function safeEscape(value: string) {
  const str = MD_REPLACEMENTS.reduce(function (accumulator, escape) {
    return accumulator.replace(escape[0], escape[1]);
  }, value);

  return escape(str);
}

export function wrapDirOnEachLine(str: string, delimiter: RegExp) {
  return str
    .split(delimiter)
    .map((line, idx) => (line.trim() && idx ? `<span dir="auto">${line}</span>` : line))
    .join('<br>');
}

export function processMarkdown(value: string) {
  return marked(wrapDirOnEachLine(safeEscape(value), /\n/));
}

export function processHTML(value: string) {
  return wrapDirOnEachLine(linkifyHtml(value, linkifyOptions()), /\n|<br\s?\/?>/g);
}
