import { Directive, nextTick } from 'vue';

/**
 * An alterative to `autofocus` attribute that takes a few considerations into account to make autofocus work and not get blocked by the browser.
 */
export const autofocusDirective: Directive<HTMLElement, string | undefined> = (el, { value }) => {
  if (typeof value === 'boolean' && value === false) {
    return;
  }

  nextTick(async () => {
    setTimeout(() => {
      el.focus?.();
    }, 200);
  });
};
