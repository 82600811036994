import { Position } from '@shared/types/models';

/**
 * Finds an HTML node with a specified dataset and selector in from the tree of the element that triggered the event
 */
export function findTargetWithDatasetAndSelector(
  e: Event,
  selector: string,
  datasetName: string,
): HTMLElement | undefined {
  let el = e.target as HTMLElement | undefined;
  // make sure we find the button that triggered the action
  if (el && el.dataset[datasetName] === undefined) {
    el = (el.closest(selector) as HTMLElement) || undefined;
  }

  return el;
}

/**
 * Get the caret position in all cases
 *
 * @returns {object} left, top distance in pixels
 */
export function getCaretTopPoint(): Position | null {
  const sel = document.getSelection();
  if (!sel) {
    return null;
  }

  const r = sel.getRangeAt(0);
  let rect;
  let r2;
  // supposed to be textNode in most cases
  // but div[contenteditable] when empty
  const node = r.startContainer;
  const offset = r.startOffset;
  if (offset > 0) {
    // new range, don't influence DOM state
    r2 = document.createRange();
    r2.setStart(node, offset - 1);
    r2.setEnd(node, offset);
    // https://developer.mozilla.org/en-US/docs/Web/API/range.getBoundingClientRect
    // IE9, Safari?(but look good in Safari 8)
    rect = r2.getBoundingClientRect();
    return { x: rect.x, y: rect.y };
  }

  if (offset < (node as any).length) {
    r2 = document.createRange();
    // similar but select next on letter
    r2.setStart(node, offset);
    r2.setEnd(node, offset + 1);
    rect = r2.getBoundingClientRect();

    return { x: rect.x, y: rect.y };
  }

  // textNode has length
  // https://developer.mozilla.org/en-US/docs/Web/API/Element.getBoundingClientRect
  rect = (node as Element).getBoundingClientRect();
  const styles = getComputedStyle(node as Element);
  const lineHeight = parseInt(styles.lineHeight);
  const fontSize = parseInt(styles.fontSize);
  // roughly half the whitespace... but not exactly
  const delta = (lineHeight - fontSize) / 2;
  return { x: rect.x, y: rect.y + delta };
}

export function isInView(el: HTMLElement, reference: HTMLElement | null = null) {
  const rect = el.getBoundingClientRect();

  if (!reference) {
    return rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
  }

  const referenceRect = reference.getBoundingClientRect();

  return (
    rect.top >= referenceRect.top &&
    rect.bottom <= referenceRect.bottom &&
    rect.left <= referenceRect.right &&
    rect.right >= referenceRect.left
  );
}

export function openInNewTab(href: string) {
  window.open(href, '_blank')?.focus();
}

const TEXT_MEASURE_CTX = document.createElement('canvas')?.getContext?.('2d');

export function getTextWidth(text: string, fontSize: number, fontWeight: string | number = '') {
  if (!TEXT_MEASURE_CTX) {
    return null;
  }

  TEXT_MEASURE_CTX.font = `${fontWeight ? fontWeight + ' ' : ''}${fontSize}px inter`;
  const metrics = TEXT_MEASURE_CTX.measureText(text);

  return metrics.width;
}

export function isInputDOMNode(event: KeyboardEvent): boolean {
  const target = (event.composedPath?.()?.[0] || event.target) as HTMLElement;

  const hasAttribute = typeof target?.hasAttribute === 'function' ? target.hasAttribute('contenteditable') : false;

  // when an input field is focused we don't want to trigger deletion or movement of nodes
  return ['INPUT', 'SELECT', 'TEXTAREA'].includes(target?.tagName) || hasAttribute;
}
