import { isInputDOMNode } from './dom';

const numpadNumberRE = /^Numpad\d$/;

export function isNumericKeyCode(code: string) {
  return code.includes('Digit') || code === 'Period' || code === 'NumpadDecimal' || numpadNumberRE.test(code);
}

export function isCursorControlKeyCode(code: string) {
  return code === 'Backspace' || code.includes('Arrow') || code === 'Delete';
}

export function filterNumericKey(evt: KeyboardEvent) {
  const isAllowed = isCursorControlKeyCode(evt.code) || isNumericKeyCode(evt.code);
  if (!isAllowed) {
    evt.preventDefault();

    return true;
  }

  return false;
}

export function nonComposedKey(key: string) {
  return (e: KeyboardEvent) => {
    if (!isKeyEqual(key, e.key)) {
      return false;
    }

    return !isInputDOMNode(e);
  };
}

export function isKeyEqual(lhs: string, rhs: string | string[]) {
  const rhsArr = Array.isArray(rhs) ? rhs : [rhs];

  return rhsArr.some(r => r.toUpperCase() === lhs.toUpperCase());
}
