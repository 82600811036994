import { useBreakpoints, useEventListener } from '@vueuse/core';
import { computed, ref } from 'vue';

export function useIsMobileScreen() {
  if (__IS_IFRAME_BUILD__) {
    return computed(() => false);
  }

  const mediaQuery = window.matchMedia('(max-width: 1279px)');
  const matches = ref(mediaQuery.matches);
  const onChange = () => {
    matches.value = mediaQuery.matches;
  };

  useEventListener(mediaQuery, 'change', onChange);

  return matches;
}

export function useAppBreakpoints() {
  const breakpoints = useBreakpoints({
    tablet: 640,
    laptop: 1024,
    desktop: 1280,
    desktopLg: 1366,
    desktopXl: 1440,
    desktop2Xl: 1536,
    desktop3Xl: 1760,
  });

  return breakpoints;
}
