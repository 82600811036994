<template>
  <slot v-if="isAllowed || custom" :allowed="isAllowed" />
  <slot v-else-if="$slots.restricted" name="restricted" />
</template>

<script setup lang="ts">
import { useIsAllowedTo } from '@shared/features/permissions';
import { MaybeArray } from '@shared/types';
import { AppUserRolePermissionEnum } from '@shared/types/graphql.gen';

const props = defineProps<{
  to: MaybeArray<AppUserRolePermissionEnum>;
  custom?: boolean;
}>();

const isAllowed = useIsAllowedTo(() => props.to);
</script>
