import { onMounted, provide } from 'vue';
import { captureMessage } from '@sentry/browser';
import { AffiliateContextKey } from '@web/injectionKeys';
import { loadScript } from '@shared/utils/render';
import { injectWithSelf, requestIdleCallback } from '@shared/utils/common';
import { OverridedMixpanel, RequestOptions as MixpanelRequestOptions } from 'mixpanel-browser';
import { merge } from 'lodash-es';
import { until } from '@vueuse/core';
import { useAppContext } from '@shared/features/app';
import { useCurrentUserContext } from '@shared/features/currentUser';
import { useAppFeaturesLookup, getFeature } from '@shared/features/featureFlags';
export interface AffiliateContext {
  getReferralId(): string | null;
}

export function useAffiliateTracking() {
  const getReferralId = () => (window as any).Rewardful?.referral || null;

  onMounted(async () => {
    await loadScript('https://r.wdfl.co/rw.js', {
      'data-rewardful': '274ddb',
    });

    const script = document.createElement('script');
    script.innerHTML = `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`;
    document.body.appendChild(script);
  });
  const ctx: AffiliateContext = {
    getReferralId,
  };

  provide(AffiliateContextKey, ctx);

  return ctx;
}

// Having the global as a promise makes sure it is lazy loaded
let MIXPANEL_GLOBAL: Promise<OverridedMixpanel | undefined> | undefined;

export type TrackEvent =
  | 'Inbox Page Clicked'
  | 'Contact Page Clicked'
  | 'Report Page Clicked'
  | 'Campaigns Page Clicked'
  | 'Chatbot Page Clicked'
  | 'Automations Page Clicked'
  | 'Status Filter Opened'
  | 'Status Filter Item Selected'
  | 'Status Filter Item Unselected'
  | 'Filter Modal Opened'
  | 'Filter Added'
  | 'Filter Removed'
  | 'Clear All Filters Clicked'
  | 'Snoozed Toggle Clicked'
  | 'Assignment Button Clicked'
  | '3 Dots Menu Opened'
  | '3 Dots Menu Mark Unread Clicked'
  | '3 Dots Menu Snoozed Clicked'
  | '3 Dots Change Status Clicked'
  | 'Right Click Menu Opened'
  | 'Grid View Clicked'
  | 'List View Clicked'
  | 'Create Conversation Button Clicked'
  | 'Search Panel Opened'
  | 'Search Panel Message Filter Clicked'
  | 'Search Panel Contact Filter Clicked'
  | 'Search Panel Recent Search Item Clicked'
  | 'Search Panel Result Message Selected'
  | 'Search Panel Result Contact Selected'
  | 'Search Panel Close Clicked'
  | 'Search Panel Close Shortcut'
  | 'Inbox Bar Collapsed'
  | 'Inbox Bar Expanded'
  | 'Unanswered Inbox Opened'
  | 'Chatbot Inbox Opened'
  | 'Mentions Opened'
  | 'Mention Archived'
  | 'Assigned To Me Inbox Opened'
  | 'Unassigned Inbox Opened'
  | 'Deleted Inbox Opened'
  | 'Assigned Conversation Section Expanded'
  | 'Assigned Conversation Section Collapsed'
  | 'Assigned Conversation Section Item Selected'
  | 'Channels Section Expanded'
  | 'Channels Section Collapsed'
  | 'Channels Section Item Selected'
  | 'Views Section Collapsed'
  | 'Views Section Expanded'
  | 'Inbox View Opened'
  | 'Quick Action Button Clicked'
  | 'Quick Action Button Shortcut'
  | 'Inbox View Edit Button Clicked'
  | 'Voice Recording Menu Opening'
  | 'Record Button Clicked'
  | 'Recording Deleted'
  | 'Emoji Menu Opened'
  | 'Emoji Menu Item Selected'
  | 'Gif Menu Opened'
  | 'Gif Menu Item Added'
  | 'Template Menu Opened'
  | 'Template Menu Closed'
  | 'Saved Replies Menu Opened'
  | 'Saved Replies Folder Opened'
  | 'Saved Replies Root Breadcrumb Clicked'
  | 'Saved Reply Menu Closed'
  | 'Note Mode Clicked'
  | 'Normal Message Mode Clicked'
  | 'Saved Reply Command Menu Opened'
  | 'Saved Reply Command Item Selected'
  | 'Comp Expired Use Template CTA Clicked'
  | 'Comp Expired Use Template Icon Clicked'
  | 'Chat Time Tooltip Opened'
  | 'Chat Message Starred'
  | 'Chat Message Unstarred'
  | 'Chat Message Copy Link Clicked'
  | 'Chat Customer Avatar Clicked'
  | 'Top Bar Tag Menu Opened'
  | 'Top Bar Tag Removed'
  | 'Top Bar Tag Added'
  | 'Top Bar Collapsed 3 Dots Menu Clicked'
  | 'Top Bar Mark Unread Clicked'
  | 'Top Bar Snooze Clicked'
  | 'Top Bar Assignment Button Clicked'
  | 'Sidebar View Profile Button Clicked'
  | 'Sidebar New Message Button Clicked'
  | 'Sidebar Find An Attribute Searched'
  | 'Sidebar Add Attribute Menu Opened'
  | 'Sidebar Add Attribute Menu Item Selected'
  | 'Sidebar Attribute Filled'
  | 'Sidebar Rich Media Filter Opened'
  | 'Sidebar Rich Media Filtered'
  | 'Sidebar Block User Button Clicked'
  | 'Sidebar Unblock User Button Clicked'
  | 'Sidebar Sidebar Collapsed'
  | 'Sidebar Sidebar Expanded'
  | 'Sidebar Starred Message Tab Opened'
  | 'Sidebar Export Conversation Button Clicked'
  | 'Workspace Page Opened'
  | 'Workspace Export All Conversations Button Clicked'
  | 'Channels Page Opened'
  | 'Users And Permissions Page Opened'
  | 'Tags Page Opened'
  | 'Data Attributes Page Opened'
  | 'Auto Replies Page Opened'
  | 'Inbox Rules Page Opened'
  | 'Saved Replies Page Opened'
  | 'Integrations Page Opened'
  | 'API Management Page Opened'
  | 'Widget Page Opened'
  | 'Billing Page Opened'
  | 'Profile Page Opened'
  | 'Notifications Page Opened'
  | 'Mobile App Page Opened'
  | 'Call To Action Clicked'
  | 'User Signed Up'
  | 'User Signed In'
  | 'User Signed Out'
  | 'User Signed Up Failed'
  | 'Team Member Invited'
  | 'Team Member Suspended'
  | 'Subscription Created'
  | 'Subscription Cancelled'
  | 'Onboarding Company Name Completed'
  | 'Onboarding Channels Completed'
  | 'Onboarding Demo Mode Clicked'
  | 'Channel Connected'
  | 'Channel Disconnected'
  | 'Channel Connected Failed'
  | 'First Conversation Created'
  | 'Proactive Message Sent'
  | 'Message Sent'
  | 'Note Message Sent'
  | 'Conversations Searched'
  | 'Conversation TableView Selected'
  | 'Conversation Normal Selected'
  | 'Shopify Connected'
  | 'Shopify Connected Failed'
  | 'Inbox Rule Created'
  | 'Inbox Rule Deleted'
  | 'Campaign Channel Users Filtered'
  | 'Campaign Preview Edited'
  | 'Campaign Publish Modal Closed'
  | 'Campaign Created'
  | 'Campaign Updated'
  | 'Campaign Published'
  | 'Campaign Deleted'
  | 'Campaign Export Report Clicked'
  | 'View Created'
  | 'View Updated'
  | 'View Deleted'
  | 'Conversation State Changed'
  | 'Conversation Snoozed'
  | 'Conversation Unsnoozed'
  | 'Bot Created'
  | 'Bot Published'
  | 'Bot Unpublished'
  | 'Bot Fallback No Reply Seconds Updated'
  | 'Bot Interrupted By App User'
  | 'Onboarding Phone Country'
  | 'Onboarding Role'
  | 'Onboarding Team Size'
  | 'Onboarding Use Case'
  | 'Role Created'
  | 'Role Updated'
  | 'Role Deleted'
  | 'Default Role Updated'
  | 'Sandbox Message Sent'
  | 'Sandbox Phone Number Submitted'
  | 'Sandbox Channel Disconnected'
  | 'AI Rewrite Menu Opened'
  | 'AI Rewrite Use Simpler Language Selected'
  | 'AI Rewrite Make Shorter Selected'
  | 'AI Rewrite Fix Grammar And Spelling Selected'
  | 'AI Rewrite Improve Written Style Selected'
  | 'AI Rewrite Change Tone Make More Personal Selected'
  | 'AI Rewrite Change Tone Make More Formal Selected'
  | 'AI Rewrite Translate Selected'
  | 'AI Rewrite Custom Prompt Selected'
  | 'AI Rewrite Undo'
  | 'AI Transcription Viewed'
  | 'AI Suggest Answer Generated'
  | 'Message Forwarded'
  | 'Summary generated'
  | 'Summary collapsed'
  | 'Summary added to note'
  | 'Summary pinned'
  | 'Summary upvoted'
  | 'Summary downvoted'
  | 'Teams And Permissions Page Opened'
  | 'Teams Section Expanded'
  | 'Teams Section Collapsed'
  | 'Team Expanded'
  | 'Team Collapsed'
  | 'Team Inbox Opened'
  | 'Team Unassigned Opened'
  | 'Team Created'
  | 'View Inbox Copy URL Button Clicked'
  | 'Team Inbox Copy URL Button Clicked'
  | 'Save as inbox View Button Clicked'
  | 'Proactive Bot Sent'
  | 'Sort Changed'
  | 'Sort Removed'
  | 'Templates page opened'
  | 'Sidebar Manage Attributes Clicked'
  | 'Import History Item Clicked'
  | 'Import Dialog Completed'
  | 'Import Dialog See Contacts Clicked'
  | 'WhatsApp Tech Provider Signup Clicked'
  | 'WhatsApp Tech Provider Signup Completed'
  | 'WhatsApp Tech Provider Signup Failed'
  | 'Flow Externally Previewed'
  | 'Flow Create Externally Clicked'
  | 'Flow Response Individual Downloaded'
  | 'Shared Views Section Expanded'
  | 'Shared Views Section Collapsed'
  | 'Public Inbox View Folder Expanded'
  | 'Public Inbox View Folder Collapsed'
  | 'Private Inbox View Folder Expanded'
  | 'Private Inbox View Folder Collapsed'
  | 'Private Views Section Expanded'
  | 'Private Views Section Collapsed'
  | 'Private Inbox View Clicked'
  | 'Public Inbox View Clicked'
  | 'Public Inbox View Folder Created'
  | 'Private Inbox View Folder Created'
  | 'Public Inbox View Created'
  | 'Private Inbox View Created'
  | 'Conversation Sidebar Attachment Clicked'
  | 'Conversation Sidebar Attachments Type Filter Changed'
  | 'Sequences Page Clicked'
  | 'Send Sequence Menu Opened'
  | 'Send Sequence Menu Closed'
  | 'Workspace Export All Contacts Button Clicked'
  | 'Chat Error Learn More Clicked';

let MIXPANEL_GLOBAL_PROPS: Record<string, any> = {};

export function useMixpanel() {
  async function init() {
    if (MIXPANEL_GLOBAL || __IS_E2E_BUILD__ || !import.meta.env.MIXPANEL_TOKEN) {
      return;
    }

    MIXPANEL_GLOBAL = import('mixpanel-browser')
      .then(mixpanel => {
        if (__IS_NATIVE_MOBILE__) {
          return undefined;
        }

        mixpanel.default.init(import.meta.env.MIXPANEL_TOKEN, { debug: import.meta.env.DEV });

        if (import.meta.env.DEV) {
          console.log('mixpanel init');
        }

        return mixpanel.default;
      })
      .catch(err => {
        captureMessage('Failed to load mixpanel', {
          contexts: {
            error: {
              message: err.message || 'No error message',
            },
          },
        });

        return undefined;
      });
  }

  async function track(
    eventName: TrackEvent,
    props?: Record<string, any>,
    opts?: MixpanelRequestOptions,
    force = false,
  ) {
    const mixpanel = await MIXPANEL_GLOBAL;
    if (!mixpanel) {
      return;
    }

    if (!force && !getFeature('feature_mixpanel_icp_customer')) {
      return;
    }
    const data = { ...(props || {}), ...MIXPANEL_GLOBAL_PROPS };
    mixpanel?.track(eventName, data, opts);
    if (__DEV__) {
      console.log('Mixpanel event', eventName, data);
    }
  }

  async function alias(email: string) {
    const mixpanel = await MIXPANEL_GLOBAL;
    mixpanel?.alias(email);
  }

  async function identify(uniqueId: string) {
    const mixpanel = await MIXPANEL_GLOBAL;
    mixpanel?.identify(uniqueId);
  }

  async function reset() {
    const mixpanel = await MIXPANEL_GLOBAL;
    mixpanel?.reset();
    MIXPANEL_GLOBAL_PROPS = {};
  }

  function setGlobalContext(props: Record<string, any>, merge = true) {
    if (merge) {
      MIXPANEL_GLOBAL_PROPS = { ...MIXPANEL_GLOBAL_PROPS, ...props };
      return;
    }

    MIXPANEL_GLOBAL_PROPS = props;
  }

  async function peopleSet(props: Record<string, any>) {
    const mixpanel = await MIXPANEL_GLOBAL;
    mixpanel?.people.set(props);
  }

  if (!MIXPANEL_GLOBAL) {
    onMounted(() => {
      requestIdleCallback(init);
    });
  }

  return {
    track,
    alias,
    identify,
    reset,
    peopleSet,
    setGlobalContext,
  };
}

let GTM_LOADED = false;

type GTagEvent = 'sign_in' | 'sign_up';

export function useGoogleTagManager() {
  (window as any).dataLayer = (window as any).dataLayer || [];

  const gtag: (typeof window)['gtag'] = function () {
    // eslint-disable-next-line prefer-rest-params
    (window as any).dataLayer.push(arguments);
  };

  (window as any).gtag = gtag;

  function init() {
    // Disable tracking on mobile app
    if (__IS_NATIVE_MOBILE__ || __IS_E2E_BUILD__ || !import.meta.env.GTM_ID) {
      GTM_LOADED = true;
      return;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    loadScript(`https://www.googletagmanager.com/gtag/js?id=${import.meta.env.GTM_ID}`)
      .then(() => {
        if (import.meta.env.DEV) {
          console.log('google tag manager init');
        }

        gtag('js', new Date());
        gtag('config', import.meta.env.GTM_ID, { debug_mode: import.meta.env.DEV });
        if (import.meta.env.GAW_ID) {
          gtag('config', import.meta.env.GAW_ID, { debug_mode: import.meta.env.DEV });
        }

        GTM_LOADED = true;
      })
      .catch(err => {
        captureMessage('Failed to load gtm script', {
          contexts: {
            error: {
              message: (err as Error).message || 'No error message',
            },
          },
        });
      });
  }

  if (!GTM_LOADED || !(window as any).dataLayer) {
    onMounted(() => {
      requestIdleCallback(init);
    });
  }

  function sendEvent(event: GTagEvent) {
    gtag('event', event, { transport_type: 'beacon' });
  }

  async function sendConversion() {
    if (!import.meta.env.GAW_ID || !import.meta.env.GAW_CONVERSION_ID) {
      return;
    }

    gtag('event', 'conversion', {
      send_to: `${import.meta.env.GAW_ID}/${import.meta.env.GAW_CONVERSION_ID}`,
    });
  }

  return {
    gtag,
    sendEvent,
    sendConversion,
  };
}

let UET_LOADED = false;

type UETEvent = 'sign_in' | 'sign_up' | 'pageLoad';

export function useBingTracking() {
  (window as any).uetq = (window as any).uetq || [];

  function trackEvent(eventName: UETEvent, ...args: any[]) {
    (window as any).uetq.push('event', eventName, ...args);
  }

  function init() {
    // Disable tracking on mobile app
    if (__IS_NATIVE_MOBILE__ || __IS_E2E_BUILD__ || !import.meta.env.UET_ID) {
      UET_LOADED = true;
      return;
    }

    loadScript('https://bat.bing.com/bat.js').then(() => {
      if (import.meta.env.DEV) {
        console.log('bing tracking init');
      }

      const key = { ti: import.meta.env.UET_ID, q: (window as any).uetq };
      (window as any).uetq = new (window as any).UET(key);
      (window as any).uetq.push('pageLoad');
    });
  }

  if (!UET_LOADED || !(window as any).uetq) {
    onMounted(() => {
      requestIdleCallback(init);
    });
  }

  return {
    trackEvent,
  };
}

interface FullViewIdentity {
  id: number;
  email: string;
  name: string;
  disableReplaysForUser: boolean;
}

let FULLVIEW_LOADED = false;
let FULLVIEW_GLOBAL: Partial<FullViewIdentity> = {};

export function useFullView() {
  const appCtx = useAppContext();
  const { user: currentUser } = useCurrentUserContext();
  const { hasFeature } = useAppFeaturesLookup();

  async function init() {
    if (__IS_NATIVE_MOBILE__ || __IS_E2E_BUILD__) {
      FULLVIEW_LOADED = true;
      return;
    }

    try {
      await until(() => appCtx.isSyncing.value).toBe(false);
      const isEnabled = hasFeature('fullview_replays');
      if (!isEnabled) {
        if (import.meta.env.DEV) {
          console.log('fullview skipped');
        }
        return;
      }

      await loadScript('https://install.fullview.io', { 'data-org': '0b5b8b63-0ea4-41b6-aff5-f3de39e01a37' });
      FULLVIEW_LOADED = true;
      if (import.meta.env.DEV) {
        console.log('fullview init');
      }

      if (currentUser?.value) {
        setGlobalContext({
          id: currentUser.value.id,
          email: currentUser.value.email,
          name: currentUser.value.fullName || currentUser.value.displayName || currentUser.value.email,
          disableReplaysForUser: !isEnabled,
        });
      }
    } catch (err) {
      console.error('Failed to load fullview script', err);
    }
  }

  function setGlobalContext(props: FullViewIdentity) {
    FULLVIEW_GLOBAL = merge(FULLVIEW_GLOBAL, props);
    if (!FULLVIEW_LOADED) {
      if (import.meta.env.DEV) {
        console.log('Fullview not loaded yet, skipping');
      }
      return;
    }

    if (import.meta.env.DEV) {
      console.log('Setting fullview identity', props);
    }

    window.$fvIdentity = FULLVIEW_GLOBAL as FullViewIdentity;
  }

  onMounted(() => {
    setTimeout(() => {
      requestIdleCallback(init);
    }, 5000);
  });

  return {
    setGlobalContext,
  };
}

let CUSTOMER_IO_LOADED = false;

type CustomerIoEvents = {
  invite_accepted: void;
  number_connect_failed: { number: string };
  number_connect_successful: { number: string };
};

export function useCustomerIO() {
  function init() {
    const siteId = import.meta.env.CUSTOMERIO_SITE_ID;
    // Disable tracking on mobile app
    if (__IS_NATIVE_MOBILE__ || __IS_E2E_BUILD__ || !siteId || window._cio) {
      CUSTOMER_IO_LOADED = true;
      return;
    }

    if (typeof window._cio === 'undefined') {
      window._cio = [] as any;

      const a = function (f: any) {
        return function (...args: any[]) {
          window._cio?.push([f].concat(Array.prototype.slice.call(args, 0)));
        };
      };

      const b = ['load', 'identify', 'sidentify', 'track', 'page'];
      for (let c = 0; c < b.length; c++) {
        if (window._cio) {
          window._cio[b[c]] = a(b[c]);
        }
      }
    }

    loadScript('https://assets.customer.io/assets/track.js', {
      id: 'cio-tracker',
      'data-site-id': siteId,
    }).then(() => {
      if (import.meta.env.DEV) {
        console.log('customer.io tracking init');
      }
    });
  }

  if (!CUSTOMER_IO_LOADED || !window._cio) {
    onMounted(() => {
      requestIdleCallback(init);
    });
  }

  function trackEvent<T extends keyof CustomerIoEvents>(eventName: T, props?: CustomerIoEvents[T]) {
    return window._cio?.track?.(eventName, props);
  }

  function reset() {
    return window._cio?.reset?.();
  }

  function identify(email: string, attrs?: Record<string, unknown>) {
    return window._cio?.identify?.({
      id: email,
      ...(attrs || {}),
    });
  }

  return {
    trackEvent,
    reset,
    identify,
  };
}
