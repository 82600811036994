import { init as initSentry } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { version } from '../../../package.json';

initSentry({
  // if not configured it won't fail
  dsn: __IS_E2E_BUILD__ ? undefined : import.meta.env.VUE_SENTRY_DSN,
  // Alternatively, use `import.meta.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: `${version}-${__SHORT_VERSION_HASH__}`,
  tracesSampleRate: 0.2,
  // disables truncation of nested objects in payload data
  normalizeDepth: 0,
  // disables the session tracking which triggers excessive requests with vue-router
  autoSessionTracking: false,

  // enable browser performance
  integrations: [new Integrations.BrowserTracing()],

  // whitelist exceptions coming only from this app
  // prevents reporting 3rd party errors from extensions
  allowUrls: [new RegExp(import.meta.env.SITE_BASE_URL), new RegExp(import.meta.env.APP_BASE_URL)],
});
