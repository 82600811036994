import { onMounted, ref } from 'vue';

type FlashMessageType = 'success' | 'error' | 'warning';

interface FlashMessage {
  body: string;
  type: FlashMessageType;
}

const FLASH_MESSAGE_KEY = 'flash_message';
const FLASHED_MESSAGE_TIMEOUT = 1000;
const message = ref<FlashMessage | null>(null);

function loadMessage() {
  try {
    const data = JSON.parse(localStorage.getItem(FLASH_MESSAGE_KEY) || 'null');
    message.value = data;
  } catch (err) {
    message.value = null;
    localStorage.removeItem(FLASH_MESSAGE_KEY);
  }
}

export function flashMessageForNextPageLoad(body: string, type: FlashMessageType) {
  localStorage.setItem(FLASH_MESSAGE_KEY, JSON.stringify({ body, type }));
}

export function useFlashMessage() {
  loadMessage();

  function flash(body: string, type: FlashMessageType) {
    const messageObj = { body, type };
    message.value = messageObj;
    localStorage.setItem(FLASH_MESSAGE_KEY, JSON.stringify(messageObj));
    setTimeout(() => {
      localStorage.removeItem(FLASH_MESSAGE_KEY);
    }, FLASHED_MESSAGE_TIMEOUT);
  }

  function clearFlashMessage() {
    message.value = null;
    localStorage.removeItem(FLASH_MESSAGE_KEY);
  }

  onMounted(() => {
    if (message.value) {
      localStorage.removeItem(FLASH_MESSAGE_KEY);
    }
  });

  return {
    flash,
    clearFlashMessage,
    message,
  };
}
