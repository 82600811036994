import * as Types from '../types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type AppInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppInfoQuery = { __typename?: 'Query', app: { __typename?: 'App', id: number, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, displayName?: string | null, featureFlags: Array<string>, account: { __typename?: 'Account', id: number, partner: boolean, subAccount: boolean, subscription: { __typename?: 'AccountSubscription', locked: boolean, status: Types.SubscriptionStatusEnum, canceledAt?: number | null, trialExpiresAt: number, willBeCanceledAt?: number | null, tier?: Types.SubscriptionTierEnum | null, paymentMode?: Types.SubscriptionPaymentIntervalEnum | null, platformType: Types.SubscriptionPlatformTypeEnum, isLegacy: boolean } } } };

export type BotUserFieldsFragment = { __typename?: 'BotUser', id: number, displayName?: string | null, createdAt: number };

export type AppBotUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppBotUsersQuery = { __typename?: 'Query', app: { __typename?: 'App', botUsers: { __typename?: 'BotUserConnection', nodes: Array<{ __typename?: 'BotUser', id: number, displayName?: string | null, createdAt: number }> } } };

export declare const AppInfo: import("graphql").DocumentNode;
export declare const BotUserFields: import("graphql").DocumentNode;
export declare const AppBotUsers: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}
export const BotUserFieldsFragmentDoc = new TypedDocumentString(`
    fragment BotUserFields on BotUser {
  id
  displayName
  createdAt
}
    `, {"fragmentName":"BotUserFields"}) as unknown as TypedDocumentString<BotUserFieldsFragment, unknown>;
export const AppInfoDocument = new TypedDocumentString(`
    query AppInfo {
  app {
    ...AppDetails
  }
}
    fragment AppDetails on App {
  id
  avatarUrl
  optimisedAvatarUrl
  displayName
  featureFlags
  account {
    id
    partner
    subAccount
    subscription {
      ...AccountSubscriptionFields
    }
  }
}
fragment AccountSubscriptionFields on AccountSubscription {
  locked
  status
  canceledAt
  trialExpiresAt
  willBeCanceledAt
  tier
  paymentMode
  platformType
  isLegacy
}`) as unknown as TypedDocumentString<AppInfoQuery, AppInfoQueryVariables>;
export const AppBotUsersDocument = new TypedDocumentString(`
    query AppBotUsers {
  app {
    botUsers {
      nodes {
        ...BotUserFields
      }
    }
  }
}
    fragment BotUserFields on BotUser {
  id
  displayName
  createdAt
}`) as unknown as TypedDocumentString<AppBotUsersQuery, AppBotUsersQueryVariables>;