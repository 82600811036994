import { KeysMatching } from '@shared/types';

/**
 * A typed version of Object.keys
 */
export function keysOf<TRecord extends Record<string, any>>(record: TRecord): (keyof TRecord)[] {
  return Object.keys(record);
}

/**
 * A typed object.values
 */
export function valuesOf<TRecord extends Record<string, any>>(record: TRecord): TRecord[keyof TRecord][] {
  return Object.keys(record).map(key => record[key]);
}

/**
 * Cleans up a collection with null values to one without null values, useful for arrays with conditional items
 */
export function toNonNullable<T>(arr: (T | undefined | null)[]): T[] {
  return arr.filter(Boolean) as T[];
}

export function keyByWithMap<TElement, TMapped>(
  list: TElement[],
  fn: (item: TElement) => TMapped,
  key: KeysMatching<TMapped, string | number>,
) {
  const record: Record<string | number, TMapped> = {};
  const length = list.length;
  for (let i = 0; i < length; i++) {
    const newItem = fn(list[i]);
    record[newItem[key] as unknown as string | number] = newItem;
  }

  return record;
}

export function lastItem<TElement>(arr: TElement[]): TElement | undefined {
  return arr[arr.length - 1];
}
