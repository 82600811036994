import { onBeforeUnmount, ComponentInternalInstance } from 'vue';
import cuid from 'cuid';
import { useEventListener } from '@vueuse/core';

export function createAppEventDispatcher<TPayload>(eventName?: string) {
  const evtName = `app-event:${eventName || cuid()}`;
  function dispatch(payload: TPayload) {
    document.dispatchEvent(new CustomEvent(evtName, { detail: payload }));
  }

  function addListener(handler: (payload: TPayload) => void, vm?: ComponentInternalInstance) {
    const handlerWrapper = (e: CustomEvent<TPayload>) => {
      handler(e.detail);
    };

    document.addEventListener(evtName, handlerWrapper as any);

    const removeListener = () => document.removeEventListener(evtName, handlerWrapper as any);
    onBeforeUnmount(removeListener, vm);

    return removeListener;
  }

  return [dispatch, addListener] as const;
}

/**
 * Adds managed listeners to determine the app active state on web platform.
 */
export function useGlobalFocusEvent(listener: (data: { isActive: boolean }) => void) {
  function onWindowFocus() {
    const isActive = [undefined, 'visible', 'prerender'].includes(document.visibilityState);
    listener({ isActive });
  }

  useEventListener(window.document, 'visibilitychange', onWindowFocus);
}

/**
 * Adds managed listeners to determine the app online state on web platform.
 */
export function useNetworkConnectivityEvent(listener: (data: { isConnected: boolean }) => void) {
  function onConnectivityChanged() {
    const isConnected = window.navigator.onLine;
    listener({ isConnected });
  }

  useEventListener(window, 'online', onConnectivityChanged);
  useEventListener(window, 'offline', onConnectivityChanged);
}

export const [dispatchAppSubscriptionChanged, onAppSubscriptionChanged] = createAppEventDispatcher<void>();
export const [dispatchChannelConnected, onChannelConnected] = createAppEventDispatcher<void>();
export const [dispatchUserRoleChanged, onUserRoleChanged] = createAppEventDispatcher<void>();
export const [dispatchAppActiveStateChanged, onAppActiveStateChanged] = createAppEventDispatcher<{
  isActive: boolean;
}>();

export const [dispatchFocusComposer, onFocusComposer] = createAppEventDispatcher<void>();
