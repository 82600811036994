<template>
  <slot v-if="isActive" />
  <slot v-else name="lockscreen" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useIsSubscriptionActive } from '@web/features/billing';

export default defineComponent({
  name: 'Paywall',
  setup() {
    const isActive = useIsSubscriptionActive();

    return {
      isActive,
    };
  },
});
</script>
