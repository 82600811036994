import { TypedDocumentNode, DocumentTypeDecoration } from '@graphql-typed-document-node/core';
import { OperationDefinitionNode } from 'graphql';
import qs from 'query-string';
import { AUTH_STORAGE_KEY } from '@shared/constants';

interface OkResponse<TResponse> {
  response: TResponse;
  headers: Record<string, string>;
  error: undefined;
  statusCode: number;
}

interface ErrorResponse {
  error: Error;
  headers: Record<string, string>;
  response: undefined;
  statusCode: number;
}

export async function makeHttpJsonRequest<TResponse = unknown>(
  url: string,
  query?: Record<string, any>,
  method?: 'post' | 'get',
  headers?: Record<string, string>,
): Promise<OkResponse<TResponse> | ErrorResponse> {
  let statusCode = -1;
  try {
    method = method || 'get';
    const { url: fetchUrl, body } = buildFetchInit(url, query, method);
    const res: Response = await fetch(fetchUrl, {
      body,
      method,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...(headers || {}),
      },
    });

    statusCode = res.status;

    const json: TResponse = await res.json();

    return {
      statusCode,
      response: json,
      headers: Object.fromEntries(res.headers),
      error: undefined,
    };
  } catch (error) {
    return {
      statusCode,
      error: error as Error,
      headers: {},
      response: undefined,
    };
  }
}

export function makeAuthenticatedHttpJsonRequest<TResponse = unknown>(
  url: string,
  query?: Record<string, string | number | string[] | number[]>,
  method?: 'post' | 'get',
) {
  const header = window.__authToken ? `Bearer ${window.__authToken}` : localStorage.getItem(AUTH_STORAGE_KEY);

  return makeHttpJsonRequest<TResponse>(url, query, method, {
    Authorization: header || '',
  });
}

export function buildFetchInit(
  url: string,
  query?: Record<string, string | number | string[] | number[]>,
  method: 'post' | 'get' = 'get',
) {
  if (method === 'get') {
    return { url: query ? qs.stringifyUrl({ url, query }) : url, body: undefined };
  }

  return { url, body: JSON.stringify(query) };
}

/**
 * Gets the operation name from a query
 */
export function getOperationName(query: string | TypedDocumentNode | DocumentTypeDecoration<any, any>) {
  const operationNameRegex = /(?:query|mutation|subscription)\s(\w+)/;
  // if a string query was used
  if (typeof query === 'string') {
    return query.match(operationNameRegex)?.[1] ?? query.split('{').shift();
  }

  if (query instanceof String) {
    const strQuery = query.toString();

    return strQuery.match(operationNameRegex)?.[1] ?? strQuery.split('{').shift();
  }

  if (typeof query === 'object' && 'definitions' in query) {
    // if an AST was used
    return (query.definitions[0] as OperationDefinitionNode)?.name?.value;
  }
}
