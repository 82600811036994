import * as Types from '../../../shared/src/types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type ChannelOnboardingFieldsFragment = { __typename?: 'AccountChannelOnboarding', id: number, uuid: string, status: Types.AccountChannelOnboardingStatusEnum, channelType: Types.AccountChannelOnboardingChannelTypeEnum, channelName: string, updatedAt: number };

export type AppUpdateMutationVariables = Types.Exact<{
  input: Types.AppUpdateInput;
}>;


export type AppUpdateMutation = { __typename?: 'Mutation', response?: { __typename?: 'App', id: number, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, displayName?: string | null, featureFlags: Array<string>, account: { __typename?: 'Account', id: number, partner: boolean, subAccount: boolean, subscription: { __typename?: 'AccountSubscription', locked: boolean, status: Types.SubscriptionStatusEnum, canceledAt?: number | null, trialExpiresAt: number, willBeCanceledAt?: number | null, tier?: Types.SubscriptionTierEnum | null, paymentMode?: Types.SubscriptionPaymentIntervalEnum | null, platformType: Types.SubscriptionPlatformTypeEnum, isLegacy: boolean } } } | null };

export type AppTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppTagsQuery = { __typename?: 'Query', app: { __typename?: 'App', tags: { __typename?: 'TagConnection', nodes: Array<{ __typename?: 'Tag', id: number, tagColor: string, tagName: string }> } } };

export type AppAccountChannelOnboardingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AppAccountChannelOnboardingQuery = { __typename?: 'Query', app: { __typename?: 'App', channelOnboardings: Array<{ __typename?: 'AccountChannelOnboarding', id: number, uuid: string, status: Types.AccountChannelOnboardingStatusEnum, channelType: Types.AccountChannelOnboardingChannelTypeEnum, channelName: string, updatedAt: number }> } };

export type AppSetOnboardedMutationVariables = Types.Exact<{
  input: Types.AppOnboardedSetInput;
}>;


export type AppSetOnboardedMutation = { __typename?: 'Mutation', response?: { __typename?: 'App', id: number, avatarUrl?: string | null, optimisedAvatarUrl?: string | null, displayName?: string | null, featureFlags: Array<string>, account: { __typename?: 'Account', id: number, partner: boolean, subAccount: boolean, subscription: { __typename?: 'AccountSubscription', locked: boolean, status: Types.SubscriptionStatusEnum, canceledAt?: number | null, trialExpiresAt: number, willBeCanceledAt?: number | null, tier?: Types.SubscriptionTierEnum | null, paymentMode?: Types.SubscriptionPaymentIntervalEnum | null, platformType: Types.SubscriptionPlatformTypeEnum, isLegacy: boolean } } } | null };

export type AppAccountOnboardingUpdateMutationVariables = Types.Exact<{
  input: Types.AccountOnboardingUpdateInput;
}>;


export type AppAccountOnboardingUpdateMutation = { __typename?: 'Mutation', response?: { __typename?: 'AccountOnboardingUpdatePayload', onboarding?: { __typename?: 'AccountOnboarding', id: number, uuid: string, status: Types.AccountOnboardingStatusEnum, updatedAt: number } | null } | null };

export type AppAccountChannelOnboardingUpdateMutationVariables = Types.Exact<{
  input: Types.AccountChannelOnboardingUpdateInput;
}>;


export type AppAccountChannelOnboardingUpdateMutation = { __typename?: 'Mutation', response?: { __typename?: 'AccountChannelOnboardingUpdatePayload', onboarding?: { __typename?: 'AccountChannelOnboarding', id: number, uuid: string, status: Types.AccountChannelOnboardingStatusEnum, channelType: Types.AccountChannelOnboardingChannelTypeEnum, channelName: string, updatedAt: number } | null } | null };

export type AccountUpdatedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type AccountUpdatedSubscription = { __typename?: 'Subscription', accountUpdated: { __typename?: 'Account', id: number, subscription: { __typename?: 'AccountSubscription', locked: boolean, status: Types.SubscriptionStatusEnum, canceledAt?: number | null, trialExpiresAt: number, willBeCanceledAt?: number | null, tier?: Types.SubscriptionTierEnum | null, paymentMode?: Types.SubscriptionPaymentIntervalEnum | null, platformType: Types.SubscriptionPlatformTypeEnum, isLegacy: boolean } } };

export type AppRedesignOptInMutationVariables = Types.Exact<{
  input: Types.AppRedesignOptInInput;
}>;


export type AppRedesignOptInMutation = { __typename?: 'Mutation', response?: { __typename?: 'AppRedesignOptInPayload', success: boolean } | null };

export declare const ChannelOnboardingFields: import("graphql").DocumentNode;
export declare const AppUpdate: import("graphql").DocumentNode;
export declare const AppTags: import("graphql").DocumentNode;
export declare const AppAccountChannelOnboarding: import("graphql").DocumentNode;
export declare const AppSetOnboarded: import("graphql").DocumentNode;
export declare const AppAccountOnboardingUpdate: import("graphql").DocumentNode;
export declare const AppAccountChannelOnboardingUpdate: import("graphql").DocumentNode;
export declare const AccountUpdated: import("graphql").DocumentNode;
export declare const AppRedesignOptIn: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}
export const ChannelOnboardingFieldsFragmentDoc = new TypedDocumentString(`
    fragment ChannelOnboardingFields on AccountChannelOnboarding {
  id
  uuid
  status
  channelType
  channelName
  updatedAt
}
    `, {"fragmentName":"ChannelOnboardingFields"}) as unknown as TypedDocumentString<ChannelOnboardingFieldsFragment, unknown>;
export const AppUpdateDocument = new TypedDocumentString(`
    mutation AppUpdate($input: AppUpdateInput!) {
  response: appUpdate(input: $input) {
    ...AppDetails
  }
}
    fragment AppDetails on App {
  id
  avatarUrl
  optimisedAvatarUrl
  displayName
  featureFlags
  account {
    id
    partner
    subAccount
    subscription {
      ...AccountSubscriptionFields
    }
  }
}
fragment AccountSubscriptionFields on AccountSubscription {
  locked
  status
  canceledAt
  trialExpiresAt
  willBeCanceledAt
  tier
  paymentMode
  platformType
  isLegacy
}`) as unknown as TypedDocumentString<AppUpdateMutation, AppUpdateMutationVariables>;
export const AppTagsDocument = new TypedDocumentString(`
    query AppTags {
  app {
    tags {
      nodes {
        id
        tagColor
        tagName
      }
    }
  }
}
    `) as unknown as TypedDocumentString<AppTagsQuery, AppTagsQueryVariables>;
export const AppAccountChannelOnboardingDocument = new TypedDocumentString(`
    query AppAccountChannelOnboarding {
  app {
    channelOnboardings {
      ...ChannelOnboardingFields
    }
  }
}
    fragment ChannelOnboardingFields on AccountChannelOnboarding {
  id
  uuid
  status
  channelType
  channelName
  updatedAt
}`) as unknown as TypedDocumentString<AppAccountChannelOnboardingQuery, AppAccountChannelOnboardingQueryVariables>;
export const AppSetOnboardedDocument = new TypedDocumentString(`
    mutation AppSetOnboarded($input: AppOnboardedSetInput!) {
  response: appOnboardedSet(input: $input) {
    ...AppDetails
  }
}
    fragment AppDetails on App {
  id
  avatarUrl
  optimisedAvatarUrl
  displayName
  featureFlags
  account {
    id
    partner
    subAccount
    subscription {
      ...AccountSubscriptionFields
    }
  }
}
fragment AccountSubscriptionFields on AccountSubscription {
  locked
  status
  canceledAt
  trialExpiresAt
  willBeCanceledAt
  tier
  paymentMode
  platformType
  isLegacy
}`) as unknown as TypedDocumentString<AppSetOnboardedMutation, AppSetOnboardedMutationVariables>;
export const AppAccountOnboardingUpdateDocument = new TypedDocumentString(`
    mutation AppAccountOnboardingUpdate($input: AccountOnboardingUpdateInput!) {
  response: accountOnboardingUpdate(input: $input) {
    onboarding {
      ...OnboardingDetails
    }
  }
}
    fragment OnboardingDetails on AccountOnboarding {
  id
  uuid
  status
  updatedAt
}`) as unknown as TypedDocumentString<AppAccountOnboardingUpdateMutation, AppAccountOnboardingUpdateMutationVariables>;
export const AppAccountChannelOnboardingUpdateDocument = new TypedDocumentString(`
    mutation AppAccountChannelOnboardingUpdate($input: AccountChannelOnboardingUpdateInput!) {
  response: accountChannelOnboardingUpdate(input: $input) {
    onboarding {
      ...ChannelOnboardingFields
    }
  }
}
    fragment ChannelOnboardingFields on AccountChannelOnboarding {
  id
  uuid
  status
  channelType
  channelName
  updatedAt
}`) as unknown as TypedDocumentString<AppAccountChannelOnboardingUpdateMutation, AppAccountChannelOnboardingUpdateMutationVariables>;
export const AccountUpdatedDocument = new TypedDocumentString(`
    subscription AccountUpdated {
  accountUpdated {
    id
    subscription {
      ...AccountSubscriptionFields
    }
  }
}
    fragment AccountSubscriptionFields on AccountSubscription {
  locked
  status
  canceledAt
  trialExpiresAt
  willBeCanceledAt
  tier
  paymentMode
  platformType
  isLegacy
}`) as unknown as TypedDocumentString<AccountUpdatedSubscription, AccountUpdatedSubscriptionVariables>;
export const AppRedesignOptInDocument = new TypedDocumentString(`
    mutation AppRedesignOptIn($input: AppRedesignOptInInput!) {
  response: appRedesignOptIn(input: $input) {
    success
  }
}
    `) as unknown as TypedDocumentString<AppRedesignOptInMutation, AppRedesignOptInMutationVariables>;