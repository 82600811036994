import {
  AppUserChangedDocument,
  AppUsersDocument,
  AppUsersQuery,
  AppUserPresenceUpdatedDocument,
} from '@shared/graphql/AppUser';
import { Unpacked } from '@shared/types';
import { keyByWithMap, valuesOf, keysOf } from '@shared/utils/collections';
import { useQuery, useSubscription } from 'villus';
import { ref, Ref, watch, computed, provide, InjectionKey } from 'vue';
import { useStoredRef } from '@shared/features/refs';
import { AppUserStatusEnum } from '@shared/types/graphql.gen';

/**
 * The App User fetched from the query
 */
export type QueryAppUser = Unpacked<AppUsersQuery['app']['appUsers']['nodes']>;

/**
 * The data model consumed by components
 */
export interface AppUserNode extends QueryAppUser {
  suspended: boolean;
  pending: boolean;
}

/**
 * Holds a ref to the app users collection during the lifecycle of the app.
 */
const appUsers = useStoredRef<Record<string | number, AppUserNode>>({}, 'appUsers');

/**
 * Last time the users were updated
 */
const fetchedAt = ref<number | undefined>(undefined);

export const AppUsersCtxInjectionKey: InjectionKey<AppUsersContext> = Symbol('All app users');

export interface AppUsersContext {
  appUsers: Ref<AppUserNode[]>;
  activeUsers: Ref<AppUserNode[]>;
  lookup(id: number): AppUserNode | undefined;
  lookupActive(id: number): AppUserNode | undefined;
  refresh(): Promise<void>;
  isFetching: Ref<boolean>;
  isSyncing: Ref<boolean>;
  setUser(user: AppUserNode): void;
}

let APP_USERS_CTX: AppUsersContext | undefined;

/**
 * Returns the app user collection along with methods to manipulate it
 */
export function useAppUsers() {
  if (APP_USERS_CTX) {
    return APP_USERS_CTX;
  }

  const { data, execute, isFetching } = useQuery({
    query: AppUsersDocument,
  });

  watch(data, value => {
    appUsers.value = keyByWithMap(value?.app.appUsers.nodes || [], mapAppUser, 'id');
    fetchedAt.value = Date.now();
  });

  // Expose a read-only version of it
  const users = computed(() => {
    return valuesOf(appUsers.value);
  });

  const activeUsers = computed(() => {
    return valuesOf(appUsers.value).filter(user => !user.suspended);
  });

  function lookup(id: number) {
    return appUsers.value[id];
  }

  function lookupActive(id: number) {
    return activeUsers.value.find(user => user.id === id);
  }

  async function refresh() {
    await execute();
  }

  useSubscription(
    {
      query: AppUserPresenceUpdatedDocument,
    },
    next => {
      const payload = next.data?.appUserPresenceUpdated;
      if (!payload) {
        return;
      }

      const appUser = appUsers.value[payload.id];
      appUser.presenceStatus = payload.presenceStatus;
    },
  );

  useSubscription(
    {
      query: AppUserChangedDocument,
    },
    next => {
      const payload = next.data?.appUserChanged;
      if (!payload) {
        return;
      }

      const appUser = appUsers.value[payload.user.id];
      if (appUser) {
        Object.assign(appUser, mapAppUser(payload.user));
        return;
      }

      appUsers.value[payload.user.id] = mapAppUser(payload.user);
    },
  );

  function setUser(user: AppUserNode) {
    appUsers.value[user.id] = user;
  }

  const context: AppUsersContext = {
    appUsers: users,
    activeUsers,
    lookup,
    lookupActive,
    isFetching: computed(() => !keysOf(appUsers.value).length && isFetching.value),
    isSyncing: isFetching,
    refresh,
    setUser,
  };

  provide(AppUsersCtxInjectionKey, context);
  APP_USERS_CTX = context;

  return context;
}

/**
 * Maps the app user to a shape more usable by the components
 */
export function mapAppUser(user: QueryAppUser): AppUserNode {
  const isSuspended = user.status === 'SUSPENDED';
  const pendingStatus: AppUserStatusEnum[] = ['PENDING_EMAIL_CONFIRM', 'PENDING_INVITE_ACCEPT'];
  const isPending = pendingStatus.includes(user.status);

  return {
    ...user,
    createdAt: user.createdAt * 1000,
    suspended: isSuspended,
    pending: isPending,
  };
}
