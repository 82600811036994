<template>
  <kbd class="kbd" :class="[`size-${size}`]">
    {{ platformKey }}
  </kbd>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { KEY_SYMBOL_MAP, KeyboardKey } from '@web/features/shortcuts';
import { getPlatform } from '@shared/utils/platform';

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  exact: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<'sm' | 'base' | 'lg'>,
    default: 'base',
  },
});

const PLATFORM_KEYS: Partial<Record<string, string>> = {
  '⌘': getPlatform() === 'Mac' ? '⌘' : 'Ctrl',
  '⌥': getPlatform() === 'Mac' ? '⌥' : 'Alt',
  enter: '⏎',
};

const platformKey = computed(() => {
  return PLATFORM_KEYS[props.value.toLowerCase()] || KEY_SYMBOL_MAP[props.value as KeyboardKey] || props.value;
});
</script>

<style scoped lang="postcss">
.kbd {
  @apply rounded inline-block align-baseline text-center capitalize font-sans leading-none;
  padding: 3px;
  min-width: 20px;
}

.size-sm {
  @apply text-sm w-6 h-6;
}

.size-lg {
  @apply text-lg text-center items-center rounded-lg;
  height: 33px;
  width: 33px;
}
</style>
