import { defineComponent, inject, InjectionKey, provide, Ref, ref, VNode, h } from 'vue';
import { injectStrict } from '@shared/utils/common';
import FloatingContainer from '@web/components/FloatingContainer.vue';
import { ComponentProps, Position } from '@shared/types';
import { Placement } from '@floating-ui/core';

export type TriggerEvent = 'click' | 'mouseenter';

export interface FloatingContainerOverrides {
  delay?: number;
  placement?: Placement;
}

export interface FloatingContainerContext<TData = unknown> {
  data: Ref<TData | null>;
  isOpen: Ref<boolean>;
  menuRef: Ref<HTMLElement | null>;
  anchorRef: Ref<HTMLElement | null>;
  position: Ref<Position | null>;
  openAtPosition(position: Position, data?: TData, opts?: FloatingContainerOverrides): void;
  openAtEventTarget(e: Event, data?: TData, opts?: FloatingContainerOverrides): void;
  openAtElement(el: HTMLElement, data?: TData, opts?: FloatingContainerOverrides): void;
  open(data?: TData): void;
  close(): void;
}

let ID = 0;

export function getAutoIncrementId() {
  return `${ID++}`;
}

export interface PrivateFloatingContainerContext<TData = unknown> extends FloatingContainerContext<TData> {
  registerContentComponent(ctx: FloatingContainerContext): void;
}

export function getFloatingContainerInjectionKey<TData = unknown>(
  name: string,
): InjectionKey<PrivateFloatingContainerContext<TData>> {
  return `floating-ctx:${name}` as unknown as InjectionKey<PrivateFloatingContainerContext<TData>>;
}

export function resolveFloatingContainerContext<TData = unknown>(name: string) {
  const ctx = injectStrict(getFloatingContainerInjectionKey(name));

  return ctx as PrivateFloatingContainerContext<TData>;
}

export function useFloatingContainerContext<TData = unknown>(id: string) {
  const key = getFloatingContainerInjectionKey<TData>(id);
  const alreadyCreated = inject(key, null);
  if (alreadyCreated) {
    return alreadyCreated;
  }

  const isOpen = ref(false);
  const data: Ref<TData | null> = ref(null);
  const anchorRef = ref<HTMLElement | null>(null);
  const menuRef = ref<HTMLElement | null>(null);
  const position = ref<Position | null>(null);
  let ctx: FloatingContainerContext<TData> | null = null;
  const privateCtx: PrivateFloatingContainerContext<TData> = {
    data,
    isOpen,
    menuRef,
    anchorRef,
    position,
    open(data) {
      ctx?.open(data);
    },
    openAtEventTarget(evt, data, opts) {
      ctx?.openAtEventTarget(evt, data, opts);
    },
    openAtElement(el: HTMLElement, data, opts) {
      ctx?.openAtElement(el, data, opts);
    },
    openAtPosition(position, data, opts) {
      ctx?.openAtPosition(position, data, opts);
    },
    close() {
      ctx?.close();
      data.value = null;
    },
    registerContentComponent(context: FloatingContainerContext<TData>) {
      ctx = context;
    },
  };

  provide<PrivateFloatingContainerContext<TData>>(key, privateCtx);

  return privateCtx;
}

export function useFloatingContainer<TData = unknown>(id: string) {
  const ctx = useFloatingContainerContext<TData>(id);
  const ContentComponent = defineComponent((props: ComponentProps<typeof FloatingContainer>, { slots }) => {
    const children = {
      menu: () =>
        slots.default?.({
          close: ctx.close,
          data: ctx.data.value,
        }),
    };

    return () => {
      return h(FloatingContainer, { ...props, id }, children);
    };
  });

  return {
    ...ctx,
    Component: ContentComponent as typeof ContentComponent & {
      new (): {
        $slots: {
          default: (arg: { data: TData; close: () => void }) => VNode[];
        };
      };
    },
  };
}
