<template>
  <div class="BaseAvatar" :class="[{ square }, `size-${size || 'base'}`]" :style="color">
    <img
      v-if="src && !hasError"
      :src="src"
      alt=""
      loading="lazy"
      class="absolute inset-0 w-full h-full object-cover"
      @error="onError"
    />
    <span v-else class="font-bold capitalize select-none">{{ initials }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Maybe } from '@shared/types';
import { firstCharacter, generateWhatsAppColorPairFromName } from '@shared/utils/string';
import { hasEmoji } from '@shared/utils/assertions';

export type BaseAvatarSize = 'xxs' | 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';

const props = defineProps<{
  name: string;
  size?: BaseAvatarSize;
  square?: boolean;
  src?: Maybe<string>;
}>();

const hasError = ref(false);
const CHAR_SIZE_MAP: Record<NonNullable<(typeof props)['size']>, number> = {
  xxs: 1,
  xs: 1,
  sm: 1,
  base: 2,
  md: 2,
  lg: 2,
  xl: 2,
  '2xl': 2,
  '3xl': 2,
};

const initials = computed(() => {
  const letters =
    props.name
      ?.split(' ')
      .slice(0, CHAR_SIZE_MAP[props.size || 'base'])
      .map(firstCharacter)
      .join('') || '';

  if (hasEmoji(letters)) {
    return firstCharacter(letters);
  }

  return letters;
});

const color = computed(() => {
  if (props.src && !hasError.value) {
    return {};
  }

  return generateWhatsAppColorPairFromName(props.name || '');
});

function onError() {
  hasError.value = true;
}
</script>

<style lang="postcss" scoped>
.BaseAvatar {
  @apply flex items-center justify-center relative flex-shrink-0 p-1 overflow-hidden rounded-full w-7 h-7 text-xs leading-4;

  &.size-full {
    @apply w-full h-full;
  }

  &.square {
    @apply rounded;
  }

  &.size-xxs {
    @apply w-4 h-4;
  }

  &.size-xs {
    @apply w-[18px] h-[18px];
  }

  &.size-sm {
    @apply w-6 h-6;
  }

  &.size-md {
    @apply w-8 h-8;
  }

  &.size-lg {
    @apply h-9 w-9;
  }

  &.size-xl {
    @apply h-10 w-10;
  }

  &.size-2xl {
    @apply h-16 w-16 text-2xl;
  }

  &.size-3xl {
    @apply h-24 w-24 text-3xl leading-9 font-medium;
  }
}
</style>
