<template>
  <component
    :is="tag"
    class="BaseButton"
    :class="[{ 'is-loading': isLoading }, `size-${size}`, variant]"
    :disabled="isLoading || disabled"
    v-bind="attrs"
  >
    <ProgressCircle v-if="isLoading" undetermined class="BaseButton--progress" />

    <slot />
  </component>
</template>

<script lang="ts" setup>
import { computed, PropType, useAttrs } from 'vue';
import { omit } from 'lodash-es';
import ProgressCircle from '@shared/components/ProgressCircle.vue';

export type BaseButtonVariant = 'primary' | 'danger' | 'secondary' | 'outline' | 'text' | 'dotted' | 'purple' | 'green';
export type BaseButtonSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';

const props = defineProps({
  variant: {
    type: String as PropType<BaseButtonVariant>,
    default: 'primary',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<BaseButtonSize>,
    default: 'base',
  },
  as: {
    type: String,
    default: 'button',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const rawAttrs = useAttrs();

const tag = computed(() => {
  if (props.disabled) {
    return 'button';
  }

  return props.as;
});

const attrs = computed<Record<string, unknown>>(() => {
  return omit(rawAttrs, ['class']);
});
</script>

<style lang="postcss" scoped>
.BaseButton {
  @apply inline-flex items-center justify-center border border-transparent border-solid gap-2 text-sm px-4 py-2 rounded-md leading-5 font-medium shadow-sm whitespace-nowrap;

  &:deep(svg) {
    @apply w-5 h-5;
  }

  &--progress {
    @apply text-blue-600 w-5 h-5;
  }

  &.size-xs {
    @apply text-xs leading-4 py-1.5 rounded;

    &:deep(svg) {
      @apply w-4 h-4;
    }

    .BaseButton--progress {
      @apply w-4 h-4;
    }
  }

  &.size-sm {
    @apply text-sm leading-5 py-1.5 rounded-md;
  }

  &.size-lg {
    @apply text-base leading-6 py-2.5 rounded-md;
  }

  &.size-xl {
    @apply text-base leading-6 rounded-md py-3;
  }

  &.primary {
    @apply bg-blue-600 text-white;

    &:hover {
      @apply bg-blue-700;
    }

    &:disabled {
      @apply bg-gray-300 text-white cursor-not-allowed;
    }
  }

  &.purple {
    @apply bg-purple-600 text-white;
    &:hover {
      @apply bg-purple-700;
    }

    &:disabled {
      @apply bg-purple-400 text-gray-100 cursor-not-allowed;
    }
  }

  &.green {
    @apply bg-green-600 text-white;
    &:hover {
      @apply bg-green-700;
    }

    &:disabled {
      @apply bg-gray-300 text-white cursor-not-allowed;
    }
  }

  &.secondary {
    @apply bg-blue-200 text-blue-700;
    &:hover {
      @apply bg-blue-300;
    }

    &:disabled {
      @apply bg-gray-300 text-gray-400 cursor-not-allowed;
    }
  }

  &.outline {
    @apply border bg-white text-gray-500 border-gray-200 px-3 outline-none;

    &:hover {
      @apply bg-gray-100;
    }

    &:deep(svg) {
      @apply text-gray-500;
    }

    &:disabled {
      @apply bg-gray-200 text-gray-400 cursor-not-allowed border-gray-200;
    }
  }

  &.dotted {
    @apply border bg-white text-gray-500 border-gray-300 border-dashed px-3 outline-none;

    &:hover {
      @apply bg-gray-100;
    }

    &:deep(svg) {
      @apply text-blue-600;
    }

    &:disabled {
      @apply bg-gray-200 text-gray-400 cursor-not-allowed border-gray-200;
    }
  }

  &.text {
    @apply border border-transparent bg-transparent px-3 outline-none text-blue-600 shadow-none;

    &:hover {
      @apply bg-gray-800 bg-opacity-5 text-blue-700;
    }

    &:disabled {
      @apply cursor-not-allowed text-gray-400 bg-gray-50;
    }

    &:deep(svg) {
      @apply text-gray-500;
    }
  }

  &.danger {
    @apply bg-red-500 hover:bg-red-600 text-white;

    &:disabled {
      @apply cursor-not-allowed bg-red-400;
    }
  }
}
</style>
