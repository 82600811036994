<template>
  <component
    :is="as || 'button'"
    class="BaseIconButton group"
    :class="[
      `size-${size || 'base'}`,
      `is-${variant || 'default'}`,
      { dark, borderless },
      'text-gray-400 hover:desktop-sm:text-gray-700 active:text-gray-800',
    ]"
    :disabled="isLoading || disabled"
    v-bind="buttonProps"
  >
    <template v-if="!loading">
      <slot />
    </template>

    <ProgressCircle v-else class="text-blue-600" undetermined size="sm" />
    <span
      v-if="label"
      :aria-disabled="disabled"
      class="BaseIconButton__label text-xs leading-4 font-medium text-gray-600 disabled:text-gray-400 ml-1"
    >
      {{ label }}
    </span>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ProgressCircle from '@shared/components/ProgressCircle.vue';

export type BaseIconButtonSize = 'xxs' | 'xs' | 'sm' | 'base' | 'lg' | 'xl';

export type BaseIconVariant = 'default' | 'outline';

const props = defineProps<{
  as?: string;
  isLoading?: boolean;
  label?: string;
  size?: BaseIconButtonSize;
  disabled?: boolean;
  pressed?: boolean | null;
  loading?: boolean;
  dark?: boolean;
  variant?: BaseIconVariant;
  borderless?: boolean;
}>();

const buttonProps = computed(() => {
  const baseProps: Record<string, string> = {};

  if (props.pressed) {
    baseProps['aria-pressed'] = props.pressed ? 'true' : 'false';
  }

  if (!props.as || props.as === 'button') {
    return {
      ...baseProps,
      type: 'button',
    };
  }

  return baseProps;
});
</script>

<style lang="postcss" scoped>
.BaseIconButton {
  @apply p-1 pressed:bg-gray-900 pressed:bg-opacity-5 hover:desktop-sm:bg-gray-900 hover:desktop-sm:bg-opacity-5 active:bg-gray-900 active:bg-opacity-10 rounded-md flex items-center focus:outline-none relative;

  &.dark {
    @apply pressed:bg-white pressed:bg-opacity-5 hover:desktop-sm:bg-white hover:desktop-sm:bg-opacity-10 active:bg-white active:bg-opacity-10 rounded-md flex items-center focus:outline-none relative text-white hover:desktop-sm:text-white active:text-white;
  }

  &:deep(svg) {
    @apply w-6 h-6;
  }

  &.size-xs {
    &:deep(svg) {
      @apply w-[18px] h-[18px];
    }
  }

  &.size-sm {
    &:deep(svg) {
      @apply w-5 h-5;
    }
  }

  &:disabled {
    @apply cursor-not-allowed hover:bg-transparent;

    &:deep(svg) {
      @apply text-gray-300;
    }
  }

  &.size-xxs {
    &:deep(svg) {
      @apply w-4 h-4;
    }
  }

  &.is-outline {
    @apply border border-gray-200 shadow-sm text-gray-600;

    &[aria-pressed='true'] {
      @apply bg-blue-600 text-white;
    }
  }

  &.borderless {
    @apply border-none rounded-none;
  }
}

.BaseIconButton__label {
  display: var(--base-icon-button-label-display);
}
</style>
